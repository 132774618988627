const isAndroid = 'Android';
const isiOS = 'iOS';

const getRouteQuery = (path, queryParam) => {
  const query = queryParam || {};
  const queryString = path.split('?')[1];
  if (!queryString) {
    return query;
  }
  queryString.split('&').forEach(item => {
    const [queryKey, queryValue] = item.split('=');
    query[queryKey] = queryValue;
  });
  return query;
};

// 兼容h5页面的方法
const h5Methods = {
  loadUrl(params, router, isReplace) {
    params.route.query = getRouteQuery(params.route.path, params.route.query);
    if (isReplace) {
      router.replace(params.route);
      return;
    }
    router.push(params.route);
  },
  finishPage(params, router) {
    router.back(-1);
  },
  toActivityPage(...args) {
    h5Methods.loadUrl(...args);
  },
  toInteractiveDiscussion(...args) {
    h5Methods.loadUrl(...args);
  },
  toLogout(...args) {
    h5Methods.loadUrl(...args);
  },
};

const APPUtil = {
  name: 'APPUtil',
  hadnleRouter(route) {
    const { path, query, params } = route;
    let url = `${window.location.origin}/h5${path}`;
    if (params) {
      // 拼接params 参数
      Object.keys(params).forEach(key => {
        url += `/${params[key]}`;
      });
    }
    let allQuery = '';
    if (query) {
      // 拼接query 参数
      Object.keys(query).forEach(key => {
        allQuery += `&${key}=${query[key]}`;
      });
    }
    allQuery = allQuery.slice(1);
    allQuery = allQuery.length ? `?${allQuery}` : '';
    return `${url}${allQuery}`;
  },
  /**
   * @methodName  调用移动端方法的名称
   * @params  参数，showTitleBar(Booble,是否显示webview头部标识，true 为不显示)，显示头部的时候 title才生效，为头部的标题，route，需要打开的路由，vue路由规范，包含 path(必填)，query，params（query，params选填）
   *@router  this.$router对象
   *@isReplace  是否替换原有路由（history中不存在原理由）
   * */
  goToMobile(methodName, params, router, isReplace) {
    if (window.currentClient === 'h5') {
      if (h5Methods[methodName]) {
        h5Methods[methodName](params, router, isReplace);
      }
    } else {
      this[methodName](params, router, isReplace);
    }
  },
  /**
   * @description 关闭移动端loading回调
   **/
  hideLoading() {
    this.JSToNativeAPP('hideLoading', 'hideLoading', '');
  },
  // 调用app中的方法
  JSToNativeAPP(methodName, androidNewMethodName, params) {
    if (window.currentClient === isAndroid) {
      if (window?.android?.[methodName] === undefined) {
        window?.android?.[androidNewMethodName](JSON.stringify(params));
      } else {
        window?.android?.[methodName](JSON.stringify(params));
      }
    } else if (window.currentClient === isiOS) {
      window.webkit.messageHandlers[methodName].postMessage(params, location.origin);
    }
  },
  // 打开webView
  loadUrl(params, router, isReplace) {
    if (isReplace) {
      router.replace(params.route);
      return;
    }
    if (!params.url) {
      params.url = this.hadnleRouter(params.route);
    }
    this.JSToNativeAPP('LoadUrl', 'loadUrl', params);
  },
  // APP跳转至首页活动
  toActivityPage() {
    this.JSToNativeAPP('ToActivityPage', 'toActivityPage');
  },
  toPolicyActivity(params) {
    this.JSToNativeAPP('ToPolicyActivity', 'toPolicyActivity', params);
  },
  toMessage() {
    this.JSToNativeAPP('ToMessage', 'toMessage');
  },
  finishPage(params) {
    this.JSToNativeAPP('FinishPage', 'finishPage', params);
  },
  toWXShare(params) {
    this.JSToNativeAPP('ToWXShare', 'toWXShare', params);
  },
  toLogout() {
    this.JSToNativeAPP('ToLogout', 'toLogout');
  },
  toLogin(params) {
    this.JSToNativeAPP('ToLogin', 'toLogin', params);
  },
  toInteractiveDiscussion() {
    this.JSToNativeAPP('ToInteractiveDiscussion', 'toInteractiveDiscussion');
  },
  toNewThread(params) {
    this.JSToNativeAPP('ToNewThread', 'toNewThread', params);
  },
  showTabBar(params) {
    this.JSToNativeAPP('ShowTabBar', 'showTabBar', params);
  },
  /**
   * 手机应用开启通知权限
   * @param {*} params type = query | setting query为查询，查询结果调用window.isAppNotifyOpen返回；setting跳转手机应用设置
   */
  controlAppNotify(params) {
    this.JSToNativeAPP('controlAppNotify', 'controlAppNotify', params);
  },
};

export default APPUtil;
