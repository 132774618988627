/**
 * axios封装 公共方法
 */

let xCsrfToken = '';
let xCsrfTokenCreated = 0;

/**
 * @description 组装返回参数
 * @param response 返回值
 * @param responseType json、blob
 * @returns {{headers, data}} response 中的 headers、data
 */
function formatResData(response, responseType) {
  let result;
  if (responseType) {
    result = {
      data: response.data,
      headers: response.headers,
    };
  } else {
    result = response.data;
  }
  return result;
}

/**
 * @description 获取csrftoken
 * @returns {string} csrftoken
 */
function getXcsrfToken() {
  return xCsrfToken;
}

/**
 * @description 设置csrftoken
 * @param token csrftoken
 */
function setXcsrfToken(token) {
  xCsrfToken = token;
}

/**
 * @description 设置 csrftoken created
 * @returns {number} 接口返回数据
 */
function getXcsrfTokenCreated() {
  return xCsrfTokenCreated;
}

/**
 * @description 返回 csrftoken created
 * @param created 接口返回数据
 */
function setXcsrfTokenCreated(created) {
  xCsrfTokenCreated = created;
}

export { formatResData, getXcsrfToken, setXcsrfToken, getXcsrfTokenCreated, setXcsrfTokenCreated };
