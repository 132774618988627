export default {
  cipherTextTitle: '密文解析',
  cipherTextTemplate: '下载模板',
  cipherTextFiles: '上传文件',
  cipherTextResult: '上传结果',
  cipherTextMail: '邮箱解析',
  cipherTextPhone: '手机号解析',
  cipherTextUid: 'uid解析',
  cipherTextAddress: '地址解析',
  excelThMail: '用户邮箱',
  excelThPhone: '用户手机号',
  excelThUid: '密文uid',
  excelThAddress: '用户地址',
  excelImport: '导入',
  excelTemplate: '解析模板',
  cipherTextPage: '模板页',
  cipherTextDownload: '立即下载',
  cipherTextDownloadResult: '下载上传结果',
  uploadSuccess: '上传成功！',
  dataFail: '数据获取失败',
  checkUpload: '请检查上传的Excel！',
  logOut: '退出',
  userAvatar: '用户头像',
  excel: {
    'ciphertextUid': '密文uid',
    'ciphertextMailbox': '邮箱密文',
    'ciphertextPhone': '手机号密文',
    'ciphertextAddress': '地址密文',
    'userMailbox': '用户邮箱',
    'result': '结果',
    'userPhone': '用户手机号',
    'uid': 'uid',
    'username': '用户名',
    'nickname': '昵称',
    'userAddress': '用户地址'
  }
}
