import { ElMessage as Message } from 'element-plus'
import common from '@/utils/common/common'

export const copyUrl = (obj, successTip) => {
  let url = common.copyUrl(obj)
  if (url) {
    Message.success(successTip)
  }
  return false
}

export const openLoginDialog = () => {
  import('@/components/refactorJdc/common/login.js').then(module => {
    const loginDialog = module.default
    loginDialog()
  })
}

// 预加载图片
export const preloadImg = (url, maxTime) => {
  return new Promise((resolve) => {
    let img = new Image()
    let imgLoaderTimer = null
    // 图片加载完毕
    const imgComplete = () => {
      img.onerror = null
      img.onload = null
      img = null
      clearTimeout(imgLoaderTimer)
      resolve(url)
    }
    img.onerror = imgComplete
    img.onload = imgComplete
    img.src = url
    if (maxTime) {
      // 如果超过最大时间后还没加载完成图片，则不再等待，此图片单独显示
      imgLoaderTimer = setTimeout(() => {
        imgComplete()
      }, maxTime)
    }
  })
}
