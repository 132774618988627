import * as types from '../mutation-types'

// state
const state = {
  fullbgMode: false,
  updatedInfo:false,
  WEChatPageCode: {
    page: '',
    params: {},
  },
}

// getters
const getters = {
}

// mutations
const mutations = {
  [types.COMMON_FULLBG_MODE_SET](state, fullbgMode) {
    state.fullbgMode = fullbgMode
  },
  [types.UPDATED_INFO](state, data) {
    state.updatedInfo = data
  },
  [types.COMMON_WECHAT_PAGE_CODE](state, { page, params }) {
    state.WEChatPageCode = { page, params }
  },
}

// actions
const actions = {
  refreshFullBgMode: ({ commit }, object) => commit(types.COMMON_FULLBG_MODE_SET, object),
  updateWeChatPage: ({ commit }, { page, params }) => {
    commit(types.COMMON_WECHAT_PAGE_CODE, { page, params })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
