import {
  Refresh as ElIconRefresh,
  ArrowRight as ElIconArrowRight,
  Plus as ElIconPlus,
  Search as ElIconSearch,
  CircleClose as ElIconCircleClose,
  DArrowLeft as ElIconDArrowLeft,
  DArrowRight as ElIconDArrowRight,
  ArrowDown as ElIconArrowDown,
  CaretTop as ElIconCaretTop,
  CaretBottom as ElIconCaretBottom,
  Delete as ElIconDelete,
  EditPen as ElIconEditPen,
  PictureFilled as ElIconPictureFilled,
  ArrowLeft as ElIconArrowLeft,
  SuccessFilled as ElIconSuccessFilled,
  Minus as ElIconMinus,
  Close as ElIconClose,
  Tickets as ElIconTickets,
  ChatDotRound as ElIconChatDotRound,
  Download as ElIconDownload,
  Message as ElIconMessage,
} from '@element-plus/icons-vue';

const install = app => {
  app.component('ElIconRefresh', ElIconRefresh);
  app.component('ElIconArrowRight', ElIconArrowRight);
  app.component('ElIconPlus', ElIconPlus);
  app.component('ElIconSearch', ElIconSearch);
  app.component('ElIconCircleClose', ElIconCircleClose);
  app.component('ElIconDArrowLeft', ElIconDArrowLeft);
  app.component('ElIconDArrowRight', ElIconDArrowRight);
  app.component('ElIconArrowDown', ElIconArrowDown);
  app.component('ElIconCaretTop', ElIconCaretTop);
  app.component('ElIconCaretBottom', ElIconCaretBottom);
  app.component('ElIconDelete', ElIconDelete);
  app.component('ElIconEditPen', ElIconEditPen);
  app.component('ElIconPictureFilled', ElIconPictureFilled);
  app.component('ElIconArrowLeft', ElIconArrowLeft);
  app.component('ElIconSuccessFilled', ElIconSuccessFilled);
  app.component('ElIconMinus', ElIconMinus);
  app.component('ElIconClose', ElIconClose);
  app.component('ElIconTickets', ElIconTickets);
  app.component('ElIconChatDotRound', ElIconChatDotRound);
  app.component('ElIconDownload', ElIconDownload);
  app.component('ElIconMessage', ElIconMessage);
};

export default install;
