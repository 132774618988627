export default {
  agreeTxt: ' I agree that user data will be stored in China',
  disagreeMessage: 'Please tick the agreement option',
  signIntroduction: 'Introduction can let others know you better',
  mutualfollowing: 'mutual following',
  followConnection: 'Follow',
  newMessags: 'NEW',
  followers: 'Followers',
  followingUserName: 'User Name',
  following: 'Following',
  unfollowing: 'Unfollow',
  connections: 'Connections',
  operation: 'Operation',
  bonusPointChange: 'Bonus point change',
  forDetail: 'For Detail',
  time: 'Time',
  totalPoints: 'Total points',
  totalExperienceValue: 'Total experience value',
  lastTime: 'Last time',
  myPoints: 'My points',
  privacyPolicy: 'Privacy Policy',
  removefriend: 'Remove Friend',
  nickname: 'Nickname',
  realName: 'Real Name',
  gender: 'Gender',
  company: 'Company',
  occupation: 'Occupation',
  mobile: 'Mobile',
  landline: 'Landline',
  email: 'Email',
  address: 'Address',
  male: 'man',
  female: 'woman',
  secret: 'secret',
  nationality: '国家/地区',
  posts: 'POSTS',
  replies: 'REPLIES',
  helpful: 'HELPFUL',
  admin: 'Admin',
  memberSince: 'Member since ',
  userName: 'Username: ',
  follow: 'Add Friend',
  unfollow: 'Friend',
  post: 'POST',
  Posts: 'Posts',
  favorite: 'Favorite',
  favorites: 'Favorites',
  profile: 'Profile',
  messages: 'Notifications',
  settings: 'Settings',
  friends: 'Friends',
  commentsMentions: 'Comments & Mentions',
  commentsUpper: 'COMMENTS',
  helpfulSub: 'Subscriptions',
  friendRequests: 'Friend Requests',
  notifications: 'Notices',
  mentioned: ' mentioned you in the post ',
  mentionsReplied: ' replied to your post ',
  replied: ' replied to your post ',
  commentToComment: ' commented on your comment to the post ',
  commentToReply: ' commented on your reply to the post ',
  replyToReply: ' replied on your reply to the post ',
  commented: ' commented to your post ',
  giveLike: ' gave a like to you in the post ',
  added: ' added your post ',
  subscription: ' to the subscription',
  request: ' sent you a friend request.',
  becameFriend: ' became your friend.',
  newPosts: 'New posts from ',
  createdOn: ' were created on ',
  checkOut: 'Check them out',
  thePost: 'The post ',
  haveCreated: ' you have created on ',
  receiveSolution: ' has received a new solution. ',
  checkItOut: 'Check it out',
  agree: 'Agree',
  agreed: 'Agreed',
  sendMessage: 'send a message',
  chat: 'Chat',
  reply: 'Replies',
  comment: 'Comments',
  re: 'Re: ',
  unfavorite: 'Unfavorite',
  unfavoritesSubscribe: 'Unfavorite',
  group: 'Group',
  forum: 'Forum',
  collection: 'Collections',
  subscribe: 'Subscribe',
  unsubscribe: 'Unsubscribe',
  exit: 'Exit',
  createdBy: 'Created by ',
  created: 'Created ',
  Replied: 'Replied ',
  lastReply: 'Last reply ',
  add: 'Added ',
  groups: 'Groups',
  forums: 'Forums',
  joined: 'Join at:',
  community: 'Community',
  userCenter: 'User Center',
  noChat: 'Choose one friend from the left message list to chat',
  send: 'send',
  moreInfo: 'See more information',
  chats: 'Chat',
  medalCenter: 'Medal Center',
  medal: 'medal',
  medals: 'Medals',
  viewAll: 'View All',
  loginNotice: 'Sign in to the community to get more content',
  emotion: 'emotion',
  img: 'IMAGE',
  edit: 'Edit',
  uploadAvatar: 'Upload Avatar',
  avatarPreview: 'Avatar preview',
  chooseAvatar: 'ChooseAvatar',
  uploadType: 'Please upload an JPG, JPEG, PNG Image',
  registerMail: 'Register Email',
  contactMail: 'Contact Email',
  verified: 'Verified',
  sendVerification: 'Send Verification',
  emailTips: 'Used to receive notifications and community news',
  nicknameTips: 'The nickname can only be modified one time.',
  pleaseEnterNickname: 'Chinese characters, letters, numbers, underscores is required',
  pleaseEnterContactEmail: 'Please enter contact email',
  mailVerifyNotice: 'Mailbox verification sent!',
  mailVerifyFailed: 'Mailbox verification failed!',
  privacy: {
    operationSucceeded: 'Operation succeeded!',
    title: 'Privacy Policy',
    agreement: '用户协议',
    tipsSign:
      'I have read all the text in this Privacy Policy. I agree to receive information about products, solutions, services and offerings from Huawei and Huawei authorized channel partners. I understand that I can unsubscribe at any time.',
    tipsSignAgree:
      'I have read all the text in this User Agreement. I agree to receive information about products, solutions, services and offerings from Huawei and Huawei authorized channel partners. I understand that I can unsubscribe at any time.',
  },
  emailSetting: {
    title: 'E-mail Reminders Setting',
    all: 'Allow E-mail notifications',
    subscriptionsTitle: 'Subscriptions',
    subforums: 'Notify me when there are new posts regarding my subscribed subforums',
    collections: 'Notify me when there are new posts regarding my subscribed collections',
    group: 'Notify me when there are new posts in the group I joined',
    privateTitle: 'Private messages, mentions and comments',
    privatePost: 'Notify me when someone replies to my post',
    privateTag: 'Notify me when someone tags me',
    privateMsg: 'Notify me when someone sends me a private message',
    privateSolution: 'Notify me when my post receives the latest solution',
    privatePopular: 'Remind me when my post is getting popular',
    rankTitle: 'Ranking system',
    rankBadges: 'Notify me when my level gets updated and when I earn new badges',
    clearPoints: 'Notify me when my HiCoins are about to expire',
  },
  account: {
    title: 'Account Deregistration',
    tips_first: 'After deregistration, personal data of your account will be deleted on this website.',
    tips_second: 'After deregistration, you cannot login again as well as operate on this website.',
  },
  approval: {
    title: 'Approval Status',
    postName: 'Post Name',
    category: 'Category',
    type: 'Type',
    releaseTime: 'Release Time',
    reviewTime: 'Review Time',
    status: 'Status',
    post: 'Post',
    comment: 'Comment',
    reply: 'Reply',
    rejected: 'Rejected',
    attachment: 'Attachment',
    passed: 'Passed',
    pending: 'Pending',
    rejectedTip: 'Your post was rejected for the following reason:',
  },
  myInvitatiton: {
    name: 'Invitation',
    bonusPoint: 'Bonus Point',
    invitedUsers: 'Invited Users',
    registeredDate: 'Registered Date',
    linkName: 'Invitation link',
    share: 'share',
    copy: 'copy',
    recordTitle: 'Successful Invitation Record',
    facebook: 'Facebook',
    twitter: 'twitter',
    linkedin: 'Linkedin',
    reddit: 'Reddit',
    email: 'E-mail',
    ruleTitle: 'Invitation Rule',
    ruleInfoOne: 'A user considered successfully invited once he levels up to ',
    ruleInfoTwo: 'Both the inviter and the invited user can only rewarded once the invited user reaches ',
    ruleInfoThree:
      'When the invited user registers successfully through the mobile phone number, he needs to wait for the page to automatically jump to the community, otherwise the invitation is deemed unsuccessful.',
    ruleLink: 'Level 1',
    ruleAction: 'Action',
    ruleCreditName: 'HiCoins',
    ruleInviter: 'Who gets ',
    copySuccess: 'Copy success!',
    mailSharing: 'Mail sharing',
    mailTip: 'Please enter the email address. Multiple emails should be separated by a semicolon (;).',
    mailPlaceholder: 'Please input the email address',
    mailRemaining: 'Remaining ',
    mailAddress: ' email address',
    emailEmptyTip: 'Email address cannot be empty',
    upgradedData: 'Upgraded to L1 Date',
    reachedL1: 'Friends who have reached L1',
    reachL1: 'Friends who are going reach L1',
    userTotal: ' users in total',
  },
  moreFriend: '更多...',
  noMoreFriend: '没有更多',
  noFavoriteData: 'No Data',
  by: '',
  here: '',
  asFavorite: 'Favorite',
  asHelpful: '',
  checkThemOut: '',
  newPost: '帖子',
};
