export default {
  messages: '私信聊天',
  myMessages: '{nickName}的私信',
  noMessages: '还没有发起聊天喔~',
  showMore: '查看更多',
  send: '发送',
  emptyMessagesCannotBeSent: '抱歉，不能发送空消息',
  emojis: '表情',
  images: '图片',
  notifications: '通知',
  myPosts: '我的帖子',
  systemNotifications: '系统提醒',
  verificationNotifications: '特别提醒',
  posts: '帖子',
  comments: '点评',
  mentions: '提到我的',
  duplicateNotificationIsIgnored: '还有{count}个相同通知被忽略 ',
  showLess: '收起',
  noNotifications: '暂无提醒内容。',
  mvp: '恭喜你，获得大V认证内测体验资格！',
  clickHereToVerifyYour: '点击这里完善资料完成认证！',
  mVPVerification: '申请大V认证',
  mVPPrivileges: '大V特权详情',
  changeProfilePicture: '更换头像',
  anMVPCanGetA: '设置新的头像,我们会为您配置专属V框',
  fieldsOfExpertise: '擅长领域',
  industries: '行业',
  products: '产品',
  solutions: '解决方案',
  others: '其他',
  addFields: '添加领域',
  title: '我的头衔',
  storageExpert: '如：云计算专家',
  profile: '个人介绍',
  describeYourExpertiseAndSkills: '详细描述擅长的领域,及专场技能,以使运营审核参考',
  statements: '声明',
  usersMustComply:
    '1. 用户需遵守《华为产品定义社区用户协议》,同意并签署《华为产品定义社区隐私声明》,如发现有违反上述协议、声明的行为,华为产品定义社区有权撤销其已生效的大V认证及特权,导致的损失由用户本人承担.',
  onlyRepresents: '2. 本认证仅代表JDC华为产品定义社区,以表彰对华为产品定义社区作出卓越贡献的用户',
  verificationIsFree: '3. 本认证免费,华为产品定义社区不会向您收取任何费用,为防诈骗,请从官方邀请渠道进入',
  jdcReservesTheRight: '4. 本认证的最终解释权归华为产品定义社区所有',
  iHaveReadAndAgree: '已阅读并同意以上声明内容',
  submit: '提交认证',
  applicationSubmitted: '已提交认证',
  submittedSuccessfully: '已成功提交!',
  yourApplicationWillBeProcessed: '我们会在三个工作日处理,成功认证后,您会获得专栏特权及专属头像框',
  oK: '我知道了',
  badgeOfHonor: '专属勋章,帅气难挡',
  primePictureFrame: '专属头像框,就是闪闪亮',
  beAColumnistInJDC: '开通专栏,让创作更自由',
  attendJDCLectures: 'JDC讲坛优先参与权',
  participateJDCOfflineActivities: 'JDC线下沙龙优先参与权',
  redeemFewerPointsForGifts: 'JDC礼品折扣兑换',
  enterYourTitle: '请填写我的头衔',
  enterYourProfile: '请填写个人介绍',
  selectAtLeastOneField: '请选择至少一项擅长领域',
  yourApplicationIsUnderReview: '您已提交认证,待审核',
  yourVerifiedPass: '您已提交认证,已通过',
  yourApplicationHasBeenRejected: '您的提交认证申请已被驳回',
  youAreNotInvited: '您暂未被邀请',
};
