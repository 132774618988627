export default {
  myAccount: 'My Account',
  following: 'Following',
  follow: 'Follow',
  message: 'Message',
  followers: 'Followers',
  follower: 'Follower',
  administrator: 'Administrator',
  huaweiExpert: 'Huawei expert',
  mVP: 'MVP',
  huaweiEmployee: 'Huawei employee',
  profile: 'Profile',
  dailyTasks: 'Daily Tasks',
  myMilestones: 'My Milestones',
  timeOnline: 'Time Online',
  registrationTime: 'Registration Time',
  lastVisited: 'Last Visited',
  expertise: 'Expertise',
  addYourExpertiseToTell: 'Add your expertise to tell us more about you.',
  edit: 'Edit',
  noExpertiseHasBeenAdded: 'No expertise has been added yet.',
  hasAchieved: 'Achieved: {count}',
  report: 'Report',
  editedSuccessfully: 'Edited successfully.',
  expertiseCanBeEditedOnce: 'Expertise can be edited once every 90 days. You need to wait an hour until the next edit.',
  expertiseCanBeEditedHours:
    'Expertise can be edited once every 90 days. You need to wait no hours until the next edit. | Expertise can be edited once every 90 days. You need to wait { count } hour until the next edit. | Expertise can be edited once every 90 days. You need to wait { count } hours until the next edit.',
  expertiseCanBeEditedDays:
    'Expertise can be edited once every 90 days. You need to wait no days until the next edit. | Expertise can be edited once every 90 days. You need to wait { count } day until the next edit. | Expertise can be edited once every 90 days. You need to wait { count } days until the next edit.',
  editExpertise: 'Edit Expertise',
  selectUpTo3Fields: 'Select up to 3 fields of expertise so we can better push content suited to you. Expertise can only be edited once every 90 days.',
  save: 'Save',
  cancel: 'Cancel',
  youCanSelectUpTo: 'You can select up to { count } field.',
  peopleAchieved: 'People achieved: { count }',
  achievedAt: 'Achieved at',
  achieved: 'Achieved',
  unlock: 'Unlock',
  unachieved: 'Unachieved',
  postsAndReplies: 'Posts and Replies',
  topic: 'Post',
  reply: 'Reply',
  all: 'All',
  lastMonth: 'Last month',
  lastMonthNew: 'Last month',
  last3Months: 'Last 3 months',
  lastYear: 'Last year',
  lastYearNew: 'Last year',
  replyOrView: 'Reply/View',
  topicPosted: 'Topic posted:',
  myExperiencePoints: 'My Experience Points',
  action: 'Action',
  pointChange: 'Point Change',
  details: 'Details',
  modified: 'Modified',
  myTasks: 'My Tasks',
  myTaskProgress: 'My Task Progress',
  myActivityScore: 'My Activity Score',
  insufficientActivityScore: 'Insufficient activity score.',
  claimGifts: 'Claim gifts.',
  giftsAlreadyClaimed: 'Gifts already claimed',
  tips: 'Tips',
  congratulationsGiftsUnlocked: 'Congratulations! Gifts unlocked.',
  experiencePointAwarded: '{giftRerward} experience point awarded.',
  oK: 'OK',
  extraPoints: 'Extra Points',
  postProductSuggestions: '1. Post product suggestions.',
  youCanLeave5Posts: 'You can leave 5 posts every day. (188 experience points per post; excludes spam posts and suggestions for consumer products).',
  post: 'Post',
  productSuggestionsEndorsedBy: '2. Product suggestions endorsed by a Huawei expert will result in 2500+ experience points. (Awarded by the administrator.)',
  weeklyTasks: 'Weekly Tasks',
  activityScoreTally: 'Activity Score Tally',
  alreadySubmitted: 'Already submitted.',
  change: 'Change',
  date: 'Date',
  activityScore: 'Activity score',
  startNow: 'Start now',
  completed: 'Completed',
  thisTaskIsOverNo: 'This task is over. No activity score can be claimed.',
  followingAndFollowers: 'Following and Followers',
  'expertise:': 'Expertise:',
  recommendedUsers: 'Recommended Users',
  '{xxx}Follower': '{xxx} follower',
  myLevel: 'My Level',
  currentLevel: 'Current Level',
  youAreAheadOf: 'You are ahead of {count} of users.',
  myPrivileges: 'My Privileges',
  earnStature: 'Earn Stature',
  period: 'Period',
  quantity: 'Quantity',
  stature: 'Stature',
  statureScoreTally: 'Stature Score Tally',
  selectTime: 'Select time',
  more: 'More',
  lastThreeMonths: 'Last three months',
  lastHalfYear: 'Last half year',
  youAreAtLevel: 'You are at Level {count1}. You need {count2} stature score to level up.',
  infinite: 'Infinite',
  pointBalance: 'Point Balance',
  obtainUse: 'Obtain/Use',
  pointsToExpire: 'Points to Expire',
  expireDate: '(Expires {expireDate})',
  postText: 'Post',
  noReplies: 'No replies',
  noPosts: 'No posts',
  smartBeanKeywords: '',
  smartBeanDescription: '',
  followingKeywords: '',
};
