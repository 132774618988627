/*
 * @Author:
 * @Date: 2022-04-29 10:00:12
 * @Description:
 */
const env = process.env.env || 'dev2';

window.currentEnv = env;

export default {
  currentEnv: env,
  logoutURL: process.env.logoutURL,
  newApiBaseURL: process.env.newApiBaseURL,
  RRURL: process.env.RRURL,
  uniportalURL: process.env.uniportalURL,
  uniportalLoginURL: process.env.uniportalLoginURL,
  uniportalMobileRegisterURL: process.env.uniportalMobileRegisterURL,
  uniportalMobileLoginURL: process.env.uniportalMobileLoginURL,
  uniportalRegisterURL: process.env.uniportalRegisterURL,
  uniportalAccountDelete: process.env.uniportalAccountDelete,
  isProd: env === 'prod', // 当前环境是否为生产环境
  curVersionEnv: process.env.curVersionEnv,
  defaultCircleId: process.env.defaultCircleId,
  defaultCertificationId: process.env.defaultCertificationId,
  overseaJDCURL: process.env.overseaJDCURL,
  dataStorageWebsite: process.env.dataStorageWebsite,
  oceanClubEventThreadIds: process.env.oceanClubEventThreadIds,
  oceanClubHomeEventThreadIds: process.env.oceanClubHomeEventThreadIds,
  oceanClubHomeTopicThreadIds: process.env.oceanClubHomeTopicThreadIds,
  huaweiUrl: process.env.huaweiUrl,
  supportHuawei: process.env.supportHuawei,
  forumHuawei: process.env.forumHuawei,
  huaweiCloud: process.env.huaweiCloud,
  reportUrl: process.env.reportUrl,
  wxOfficialAccountId: process.env.wxOfficialAccountId,
  edmEndPointUrl: process.env.edmEndPointUrl,
  wxloginUrl: process.env.wxloginUrl,
};
