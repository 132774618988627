import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
// $message 样式
import 'element-plus/theme-chalk/el-message.css'
// $notify 样式
import 'element-plus/theme-chalk/el-notification.css'
// $msgbox/$messageBox/$alert/$confirm/$prompt 样式
import 'element-plus/theme-chalk/el-message-box.css'
const install = (app) => {
  app.use(ElMessage)
  app.use(ElMessageBox)
  app.use(ElNotification)
}

export default install
