const errCode = {
  networkErr: 'ERR_NETWORK',
  name: 'errCode',
  success: '000000',
  noData: '010015',
  noLogin: '010100',
  noPermission: '020505', // 没有权限进行此操作
  noVisitPermission: '020506', // 没有权限访问
  logging: '020504', // 没有权限进行此操作
  joinGroup: '000203', // 已经加入该圈子
  checkJoin: '000201', // 已经申请加入圈子，请等待群主审核
  bannerNoPerm: '020509', // 圈子封面暂无权限
  noFid: '020508', // 圈子没有fid
  errorParam: '010700', // 参数不正确
  sign: '010915', // 已经补签过
  signTodo: '010917', // 待完成补签任务
  replenishSign: '010918', // 不需要补签到
  joinGroupSuccess: '000200', // 加圈成功
  errorPassCode: '020110', // 五次错误口令，冻结2小时
  joinGroupCheck: '000202', // 加圈审核
  apiNoLogin: '000001', // 登录接口未登录
  csrfCode: 412, // csrf状态码
  flowControlCode: 429, // 流控状态码
  noUpdatedInfo: '010919', // 未更新用户信息
  oldUpdatedInfo: '2005', // 老服务未更新用户信息
  oldSuccess: '0000', // 老服务成功标识
  notLogin: '2000', // 用户未登录
  notJoin: '020705', // 未加圈，私密圈需要申请加入
  checkNickName: '010911', // 校验昵称是否重复
  changNickName: '010914', // 昵称已经修改过
  phoneNumberCode: '010501', // 验证码错误
  notAgree: '010400', // 未签署用户协议或隐私声明
  denyAccess: '023010', // 禁止访问
  hasCompleted: '010920', // 已完善用户信息
  misMatchSuffix: '030507', // 扩展名与文件实际格式不一致，请检查后重新上传
  hasViolation: '025007', // 附件可能涉及违规内容，请修改后重试
  fileNotSupported: '030502', // 文件类型不支持
  violationNickName: '025001', // 该昵称不可用，试试别的吧
  exceededDraftMaximum: '000700', // 超出问卷贴草稿最大范围
};

export default errCode;
