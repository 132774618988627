/**
 * @description 存储海外 所有API接口
 */

const baseURL = '/CommunityGatewayService/com.huawei.ipd.sppm.jdcforum:';

const service = {
  user: 'JDCCommunityUserService/CommunityUserService',
  gateway: '/CommunityGatewayService',
  forum: 'JDCCommunityGroupService/CommunityGroupService',
  post: 'JDCCommunityPostService/CommunityPostService',
};

const oceanClubApi = {
  user: {
    login: `${baseURL}${service.user}/jdc/rest/sso/postLogin`,

    // 获取行业列表
    getCommonIndustry: `${baseURL}${service.user}/jdc/api/profile/industry`,
    profileInfo: `${baseURL}${service.user}/jdc/api/user/profile/`,
    logout: `${baseURL}${service.user}/jdc/rest/sso/logout`,

    // 查询国家地区
    getRegionList: `${baseURL}${service.user}/jdc/api/userinfo/getRegionCodeList`,
    profileBase: `${baseURL}${service.user}/jdc/api/profile/base`,

    // 邮箱订阅
    profileEmail: `${baseURL}${service.user}/jdc/api/profile/email`,

    // 个人设置增加“个性化推荐”开关
    personalizedRecommendation: `${baseURL}${service.user}/jdc/api/profile/personalizedRecommendation/update`,

    // 隐私声明签署
    privacy: `${baseURL}${service.user}/jdc/api/profile/privacy`,
    emailCheck: `${baseURL}${service.user}/jdc/api/emailcheck`,

    // 上传用户头像
    uploaduseravatar: `${baseURL}${service.user}/jdc/api/avatar/upload`,
    emailCheckSend: `${baseURL}${service.user}/jdc/api/emailcheck/send`,

    // 更新个人联系方式
    contact: `${baseURL}${service.user}/jdc/api/personal/contact`,

    // 修改手机号获取验证码
    send: `${baseURL}${service.user}/jdc/api/common/sms/send`,

    // 完善用户信息
    updateUserInfo: `${baseURL}${service.user}/jdc/api/userinfo/updateUserInfo`,

    // 查询隐私声明和签署协议
    getPrivacy: `${baseURL}${service.user}/jdc/api/common/privacyStatement`,

    // 修改手机号
    user: `${baseURL}${service.user}/jdc/api/user`,
  },
  gateway: {
    getToken: `${service.gateway}/jdc/api/csrftoken`,
  },
  forum: {
    getFirstRecommend: `${baseURL}${service.forum}/jdc/api/group/firstrecommend`,
  },
  post: {
    // uem 数据更新
    updateUemData: `${baseURL}${service.post}/jdc/api/uem/updateUemData`,
  },
};

export default oceanClubApi;
