export default {
  homeTitle: 'Home',
  homeKeywords: '',
  homeDescription: '',
  messageTitle: '',
  messageKeywords: '',
  messageDescription: '',
  beanTitle: '',
  beanKeywords: '',
  beanDescription: '',
  groupTitle: '',
  groupKeywords: '',
  groupDescription: '',
  replyTitle: '',
  replyKeywords: '',
  replyDescription: '',
  inviteTitle: '',
  inviteKeywords: '',
  inviteDescription: '',
  likeTitle: '',
  likeKeywords: '',
  likeDescription: '',
  atTitle: '',
  atKeywords: '',
  atDescription: '',
  systemTitle: '',
  systemKeywords: '',
  systemDescription: '',
  chatTitle: '',
  chatKeywords: '',
  chatDescription: '',
  beanrecordTitle: '',
  beanrecordKeywords: '',
  beanrecordDescription: '',
  prizerecordTitle: '',
  prizerecordKeywords: '',
  prizerecordDescription: '',
  groupIntroTitle: '',
  groupIntroKeywords: '',
  groupIntroDescription: '',
  groupDetailTitle: '',
  groupDetailKeywords: '',
  groupDetailDescription: '',
  completedInfoTitle: '',
  completedInfoKeywords: '',
  completedInfoDescription: '',
  postDetailTitle: 'Post',
  postDetailKeywords: '',
  postDetailDescription: '',
  safetyTipsTitle: '',
  safetyTipsKeywords: '',
  safetyTipsDescription: '',
  searchTitle: '',
  searchKeywords: '',
  searchDescription: '',
  relatedUserTitle: '',
  relatedUserKeywords: '',
  relatedUserDescription: '',
  relatedCircleTitle: '',
  relatedCircleKeywords: '',
  relatedCircleDescription: '',
  reportTitle: '',
  reportKeywords: '',
  reportDescription: '',
  devicesTitle: '',
  devicesKeywords: '',
  devicesDescription: '',
  newThreadTitle: 'Create Post',
  newThreadKeywords: '',
  newThreadDescription: '',
  tradeRecordTitle: '',
  tradeRecordKeywords: '',
  tradeRecordDescription: '',
  qrCodeTitle: '',
  qrCodeKeywords: '',
  qrCodeDescription: '',
  qrDetailTitle: '',
  qrDetailKeywords: '',
  qrDetailDescription: '',
  feedbackTitle: '',
  feedbackKeywords: '',
  feedbackDescription: '',
  pushSettingTitle: '',
  pushSettingKeywords: '',
  pushSettingDescription: '',
  privacySecurityTitle: '',
  privacySecurityKeywords: '',
  privacySecurityDescription: '',
  signInTitle: '',
  signInKeywords: '',
  signInDescription: '',
  myCollectionTitle: '',
  myCollectionKeywords: '',
  myCollectionDescription: '',
  personalSignTitle: 'Personal Sign',
  personalSignKeywords: '',
  personalSignDescription: '',
  lotteryTitle: 'HUAWEI JDC',
  lotteryKeywords: '',
  lotteryDescription: '',
  myPostTitle: 'My Posts',
  myPostKeywords: '',
  myPostDescription: '',
  myFocusTitle: '',
  myFocusKeywords: '',
  myFocusDescription: '',
  myFansTitle: '',
  myFansKeywords: '',
  myFansDescription: '',
  dailyTasksTitle: '',
  dailyTasksKeywords: '',
  dailyTasksDescription: '',
  hisHomePageTitle: '',
  hisHomePageKeywords: '',
  hisHomePageDescription: '',
  selectUserTitle: '',
  selectUserKeywords: '',
  selectUserDescription: '',
  exclusiveTitle: '',
  exclusiveKeywords: '',
  exclusiveDescription: '',
};
