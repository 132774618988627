/*
 * @Date: 2024-06-26
 * @Description: 圈子资源文件
 */
export default {
  myGroups: '我的圈子',
  noJoinedGroup: '当前您还没有关注圈子',
  all: '全部',
  cancel: '取消',
};
