export default {
  shippingAddress: '收货地址',
  completeInformation: '完善信息',
  perTime: '',
  isLogin: '您当前未登录，请先登录！',
  record: '抽奖记录',
  profile: '个人信息',
  hitRecord: '中奖记录',
  rules: '活动规则',
  lottery: '抽奖',
  lotteryNow: '立即抽奖',
  last: '还有',
  chance: '次',
  chances: '次',
  exploreNotSupport: '抱歉！浏览器不支持。',
  opportunity: '机会',
  timesCost: '每次消耗智豆',
  lastAviable: '可用剩余智豆',
  perLotteryLast: '剩余次数',
  freeTimes: '免费次数',
  contribute: '贡献',
  experience: '经验',
  perDayCirce: '每天周期',
  perLotteryCirce: '活动周期',
  name: '姓名',
  phone: '手机号',
  address: '地址',
  email: '邮箱',
  company: '公司名称',
  industry: '行业',
  lotteryTips: '此信息仅用于沟通和奖品寄送，我们会保护好您的隐私',
  lotteryEnd: '活动已结束！',
  lotteryNotStart: '活动尚未开始！',
  lotteryStart: '距活动开始：',
  exchange: '去兑奖',
  redeem: '去兑奖',
  exchanged: '已兑奖',
  exchangeTips: '点击确定后视为已兑换奖励，是否继续？',
  confirmExchange: '确定兑奖',
  exchangeSuccess: '兑奖成功！',
  noLotteryTimes: '您今日抽奖机会已用完，请改天再来！',
  congradulate: '恭喜',
  hit: '抽中',
  limitHw: '对不起！您是华为员工，不能参与此抽奖活动哦！',
  phoneWrongTips: '手机号格式有误！',
  emailWrongTips: '请输入有效的电子邮箱！',
  refreshSuccess: '更新成功！',
  miss: '您和大奖擦肩而过',
  sorry: '很遗憾！',
  recordCheck: '可在抽奖记录中查看',
  congradulateHit: '恭喜',
  get: '获得',
  congrats: '恭喜你获得“{title}”',
  congratulation: '恭喜你获得“{title}”',
  lotteryKeywords:
    '抽奖,奖品,华为,JDC,行业数字化,有奖活动,技术研讨,线上讨论,官方活动,活动颁奖,线下沙龙,技术分享,视频,直播,数字化,行业交流,知识共享,技术创新,知识社区',
  lotteryDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
};
