export default {
  eventStatus: '活动状态',
  searchByKeyword: '搜索活动…',
  getLuckyDrawChances: '参与可抽奖',
  toBeAwarded: '待颁奖',
  viewAward: '查看颁奖帖',
  all: '全部',
  finished: '已结束',
  ongoing: '进行中',
  awarded: '已颁奖',
  upcoming: '即将开始',
  noMoreData: '没有更多了',
  noMatch: '无匹配',
  overMaximumLength: '参数超过长度限制',
  allEventTips: '活动-华为JDC 参与华为技术探讨，赢丰厚好礼/华为社区',
  enterEventsTips: '华为,JDC,行业数字化,有奖活动,技术研讨,线上讨论,官方活动,活动颁奖,线下沙龙,技术分享,视频,直播,数字化,行业交流,知识共享,技术创新,知识社区',
  shareYourVoice:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  loading: '加载中',
  allStatus: '全部状态',
  noActivity: '暂无活动',
  noRelatedFound: '未搜索到相关活动',
  noParticipated: '你还暂未参与活动',
  getInvolved: '快去参与吧',
};
