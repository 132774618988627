/*
 * @Author:
 * @Date: 2022-04-15 09:15:09
 * @Description:
 */
const PROTOCO = 'https://';

export default {
  searchTips: '请输入两个及以上字符',
  noDataTips: '暂无',
  pleaseSelect: '请选择',
  pleaseEnter: '请输入',
  pleaseInputKeyword: '请输入关键字',
  save: '保存',
  infoCompleceTips: '请将信息填写完整！',
  hint: '提示',
  promptInformation: '提示信息',
  confirm: '确定',
  cancel: '取消',
  plsSelect: '请选择',
  selectFile: '选择文件',
  loading: '加载中',
  jdc: '华为JDC',
  noData: '暂无数据',
  goTop: '回到顶部',
  goBottom: '回到底部',
  support: '技术支持',
  contactUs: '联系我们',
  advice: '提出建议',
  search: '查询',
  searchFor: '搜索',
  reset: '重置',
  login: '登录',
  sign: '签到',
  post: '发帖',
  reply: '回帖',
  like: '点赞',
  success: '成功',
  to: '至',
  and: '和',
  collection: '收藏',
  comment: '点评',
  all: '全部',
  zh: '中文',
  en: '英语',
  ru: '俄语',
  es: '西班牙语',
  fr: '法语',
  minute: '分',
  day: '天',
  noProxy: '抱歉，您无权使用此功能!',
  uploadSuccess: '上传成功！',
  backToHomePage: '回到首页',
  notFound: '对不起，此页面不存在！',
  submit: '提交',
  yes: '是',
  no: '否',
  days: '{count} 天',
  header: {
    cookieTip: '本站点使用Cookies，继续浏览表示您同意我们使用Cookies。',
    cookieTipLink: 'Cookies和隐私政策>',
  },
  footer: {
    cookieTips: '我们使用cookies确保您的高速浏览体验,继续浏览本站,即表示您同意我们使用cookies.',
  },
  umeditor: {
    fileStyleSupportRR: '文件不能超过100M',
    fileStyle: '不支持的文件类型',
    uploading: '正在上传',
    VIDEO: '视频',
    compressVideo: '视频码率超过6000KBPS，请压缩后再上传',
  },
  messageTip: {
    newMessageInfo: 'You have new message,',
    newMessageUrl: 'go to check it',
    busy: '系统忙，请等待或刷新页面',
    beReviewed: '抱歉，指定的内容不存在或已被删除或正在被审核',
  },
  ueditor: {
    ueditorTitle: 'Image',
    ueditorLocalPicture: 'Local Upload',
    ueditorOnlinePicture: 'Network Pictures',
    ueditorPicturetitle: 'No files selected',
    ueditorNoData: 'Please enter image description',
    ueditorMaxLength: 'Please enter less than 50 characters',
    ueditorImgTitle: 'Support drag upload',
    ueditorAdd: 'Add',
    ueditorCancel: 'Cancel',
    ueditorSave: 'OK',
    numberExceeds: 'Upload limit to the number!',
    insertCode: 'insert code',
    imgPlaceholder: 'Enter a description',
    atTitle: 'Select People You Want to @ Mention:',
    atMyFollowers: 'My Followers',
    atMyFollowings: 'My Followings',
    atRecentContacts: 'Recent Contacts',
  },
  linkUrl: `${PROTOCO}support.huawei.com/carriersearch/gsSearch#currentPage=1&groupSpaceId=ALL&size=10&topicordoc=doc&title=`,
  tags: {
    top: '置顶',
    hot: '热门',
    essences: '精华',
    rewarded: '帖子被赞赏',
    locked: '帖子被关闭',
    reposted: '转载帖',
    attachments: '包含附件',
    repliesLimit: '帖子仅作者及授权专家可见',
    authenticated: '认证用户',
    vip: 'VIP用户',
    administrator: '管理员',
    administratorHover: 'JDC管理员',
    groupOwner: '圈主',
    groupOwnerHover: '{value}圈主',
    moderator: '版主',
    moderatorHover: '{value}版主',
    banned: '禁止发言',
    bannedHover: '该用户账号处于禁止发言状态',
    accessForbidden: '禁止访问',
    accessForbiddenHover: '该用户账号处于限制访问状态',
    authorBannedTitle: '禁止发言',
    authorAccessForbiddenTitle: '禁止访问',
    newReviews: '有新的点评',
    invited: '邀你参与',
    newReplies: '有新的回复',
    invitedAns: '邀你回答',
    surprised: "有人{'@'}了你",
  },
  huaweiJDC: '华为JDC /华为社区',
  flowControlTips: '当前使用人数过多，请稍后再试',
  gotIt: '我知道了',
};
