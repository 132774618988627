import * as types from '../mutation-types'
import RouterUtils from '@/utils/common/RouterUtils'

// state
const state = {
  items: [],
  lang: RouterUtils.getLang(window.location.href, localStorage.getItem('lang')),
  huaweiconnect: '',
  app: ''
}

// getters
const getters = {
  sumRouter: state => {
    let str = ''
    str += state.huaweiconnect ? ('/' + state.huaweiconnect) : ''
    str += state.app ? '/' + state.app : ''
    if (state.app !== 'jdc') {
      str += state.lang ? '/' + state.lang : ''
    }
    return str
  }
}

// mutations
const mutations = {
  [types.ROUTER_HUAWEICONNECT_SET] (state, huaweiconnect) {
    state.huaweiconnect = huaweiconnect
  },
  [types.ROUTER_APP_SET] (state, app) {
    state.app = app
  },
  [types.ROUTER_CLEAR] (state) {
    state.items = []
  },
  [types.ROUTER_LANG_SET] (state, lang) {
    state.lang = lang
  },
  [types.ROUTER_ADD] (state, item) {
    state.items.push(item)
  }
}

// actions
const actions = {
  setMallRouter ({ commit }, arys) {
    if (!Array.isArray(arys)) {
      return false
    }
    // 1.默认先清空
    commit(types.ROUTER_CLEAR)
    for (let i = 0; i < arys.length; i++) {
      // 2.配置路由数据
      commit(types.ROUTER_ADD, arys[i])
    }
  },
  getApp ({ commit }, base) {
    let app = RouterUtils.getApp(base)
    commit(types.ROUTER_APP_SET, app)
  },
  getHWConnect ({ commit }, base) {
    let huaweiconnect = RouterUtils.getHWConnect(base)
    commit(types.ROUTER_HUAWEICONNECT_SET, huaweiconnect)
  },
  getLang ({ commit }, base, localStorageLang) {
    let lang = RouterUtils.getLang(base, localStorageLang)
    commit(types.ROUTER_LANG_SET, lang)
  },
  refreshLang ({ commit }, lang) {
    commit(types.ROUTER_LANG_SET, lang)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
