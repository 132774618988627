import * as types from '../mutation-types';

import baseApi from '@/axios/baseApi.js';
import env from '@/config/env.js';
import { needCompleteUserProfile } from '@/store/modules/commonFun';
import errCode from '@/utils/common/errCode.js';
import routerUtils from '@/utils/common/RouterUtils';
import othersUtil from '@/utils/h5/othersUtil.js';
import { IS_OCEANCLUB, IS_OVERSEAS } from '@/utils/web/constant';
import { isMobile } from '@/utils/common/CommonUtils';

let router;
const baseRouterUrl = routerUtils.getBaseRouterUrl();
const baseHrefUrl = routerUtils.getBaseHrefUrl();

// state
const state = {
  isInnerIP: false,
  userInfoGet: false,
  isTrustlist: false,
  isLogin: false,
  firstLogin: false, // 打开页面调用的第一个接口postLogin设置登录标志
  closeLogin: false,
  showLoginDialog: false,
  timer: {
    wechatTimer: null,
    appTimer: null,
  },
  baseInfo: {
    uid: '',
    userName: '',
    nickname: '',
    userImageUrl: '',
    address: '',
    nickName: '',
    phoneNumber: null,
    email: '',
    escNewpl: 0,
    escNewpm: 0,
    company: '',
    creditlist: [], // 企业 帖子积分类型
    industry: {},
    industryList: [],
    contribute: 0,
    wisdomBean: 0,
    isAdmin: 0,
    isBigVUser: 0,
    isHwEmployee: 0,
    isEditorGroup: 0,
    Title_name: 'LV',
    posts: 0,
    replies: 0,
    helpful: 0,
    gender: 0,
    nationality: '--',
    title: '',
    isPrivacyStatementVerified: null,
    personalSign: '',
    inviteRegisterUrl: '',
    privacyStatementLabel: 0, //  0 -> 都没签  1 -> 没签隐私协议  2 -> 没签用户协议  null -> 都签了
  },
  tradeInfo: {
    pending: 0,
    raised: 0,
    joined: 0,
    succeed: 0,
  },
  industryList: [],
  productFieldList: [],
};

// getters
const getters = {};

// mutations
const mutations = {
  [types.USER_BASEINFO_UPDATE](state, user) {
    Object.assign(state.baseInfo, user);
  },
  [types.USER_MALL_TRADE_UPDATE](state, user) {
    Object.assign(state.tradeInfo, user);
  },
  [types.USER_ISLOGIN](state, isLogin) {
    Object.assign((state.isLogin = isLogin));
  },
  [types.USER_INFOGET](state, isGet) {
    Object.assign((state.userInfoGet = isGet));
  },
  [types.USER_INNERIP](state, isInnerIP) {
    Object.assign((state.isInnerIP = isInnerIP));
  },
  [types.USER_TRUSTLIST](state, isTrustlist) {
    Object.assign((state.isTrustlist = isTrustlist));
  },
  [types.CLOSE_LOGIN](state, closeLogin) {
    Object.assign((state.closeLogin = closeLogin));
  },
  [types.SHOW_LOGIN_DIALOG](state, showLoginDialog) {
    Object.assign((state.showLoginDialog = showLoginDialog));
  },
  [types.SET_TIMER](state, data) {
    const { type, timerCallback, time } = data;
    state.timer[type] = setInterval(timerCallback, time);
  },
  [types.CLEAR_TIMER](state, data) {
    const { type } = data;
    clearInterval(state.timer[type]);
  },
  [types.HANDLE_IMAGE](state, image) {
    state.baseInfo.userImageUrl = image;
  },
  [types.FIRST_LOGIN](state, data) {
    state.firstLogin = data;
  },
  [types.INDUSTRYLIST_UPDATE](state, list) {
    state.industryList = list;
  },
  [types.PRODUCTFIELD_UPDATE](state, list) {
    state.productFieldList = list;
  },
};

const getProfileInfoParams = isError => {
  const params = {};
  if (isError) {
    params.isError = true; // 标识404页面
  }
  params.noencryption = 1;
  params.isPc = 1;
  // 是否隐藏email返回值入参
  if (window.location.href.includes(`${baseRouterUrl}/settings`)) {
    params.isShowEmail = 1;
  } else if (
    window.location.href.includes(`${baseRouterUrl}/portal`) ||
    window.location.href.includes(`${baseRouterUrl}/viewthread`) ||
    window.location.href.includes(`${baseRouterUrl}/viewForum`) ||
    window.location.href.includes(`${baseRouterUrl}/overSeasComplete`)
  ) {
    params.isShowEmail = 2;
  }
  return params;
};

/**
 * 获取当前href
 * @returns {string}
 */
const getCurrentHref = () => encodeURIComponent(window.location.href);

/**
 * 华为内网登录
 * @param pathArr 需要登录才能访问的路由
 */
const errorGetProfileJump = pathArr => {
  const locationUrl = window.location.href;
  pathArr.forEach(item => {
    if (locationUrl.indexOf(item) > 0) {
      localStorage.setItem('goToFlag', '1');
      const currentHref = getCurrentHref();
      const jdcBaseUrl = locationUrl.includes('/refactor') ? '/jdc/refactor' : '/jdc';
      const baseUrl = IS_OCEANCLUB ? '' : jdcBaseUrl;

      window.open(`${baseUrl}/portal?backUrl=${currentHref}`, '_self');
    } else {
      localStorage.setItem('goToFlag', '0');
    }
  });
};
/**
 * @description 账号合并
 * @param response 当前登录用户信息
 * @returns {Promise<boolean>} 账号合并之后进行登出操作
 */
const isMergedLogout = async response => {
  if (response.data?.uid > 0 && response.data?.isMerged) {
    const logoutUrl = baseApi.refactorApi.user.logout;
    const queryUrl = getCurrentHref();
    const res = await baseApi.GET(`${logoutUrl}?referer=${queryUrl}`, null, env.newApiBaseURL).catch(err => err);
    if (res.code === errCode.success) {
      window.location.href = res.loginUrl;
      return true;
    }
  }
  return false;
};

/**
 * @description 获取用户信息成功后 跳转页面
 * @param backUrl 需要返回的页面
 * @param needLoginPath 需要登录才能访问的路由
 */
const routerBackUrl = (backUrl, needLoginPath) => {
  const matchUrl = decodeURIComponent(backUrl);
  const { pathname, search } = new URL(matchUrl);
  if (needLoginPath.some(item => `${pathname}${search}` === `${IS_OVERSEAS ? '' : '/jdc'}${item}`)) {
    window.open(matchUrl, '_self');
  } else {
    window.open(`${baseHrefUrl}/portal`, '_self');
  }
};

// actions
const actions = {
  async refreshRefactorUser({ commit }, { isError, route }) {
    const pathArr = []; // pathArr 需要登录才能访问的路由
    router = await routerUtils.getCurrentRouter();

    router.options.routes.forEach(item => {
      if (item.meta.needLogin) {
        pathArr.push(item.path);
      }
    });

    const params = getProfileInfoParams(isError);
    const response = await baseApi.GET(`${baseApi.refactorApi.user.profileInfo}0`, params, env.newApiBaseURL);
    commit(types.USER_INFOGET, true);
    const backUrl = route.query.backUrl;
    if (response.code === errCode.success) {
      commit(types.USER_ISLOGIN, true);
      localStorage.setItem('goToFlag', '0');
      if (response.data) {
        commit(types.USER_BASEINFO_UPDATE, response.data);
        commit(types.HANDLE_IMAGE, response.data.userImage);
        if (response.data.accountType === 'wechat') {
          window.hwa('setUserId', response.data.nickname);
        }
        // 账号合并之后进行登出操作
        await isMergedLogout(response);
        if (IS_OVERSEAS && isMobile() && needCompleteUserProfile(response.data)) {
          // 海外版H5未完善用户信息跳到H5的完善用户信息界面
          window.open('/h5/enCompletedInfo', '_self');
          return;
        }
        // 海外新注册的用户在签署隐私声明、用户协议后强制完善个人信息  isOverSeasComplete: 判断是否已经登录、已签署隐私声明、用户协议
        if (IS_OVERSEAS && needCompleteUserProfile(response.data) && router.hasRoute('OverSeasComplete')) {
          if (window.location.pathname !== `${baseHrefUrl}/overSeasComplete`) {
            const currentHref = getCurrentHref();
            window.open(`${baseHrefUrl}/overSeasComplete?redirect=${currentHref}`, '_self');
          }
          return;
        }
      }
      if (backUrl) {
        routerBackUrl(backUrl, pathArr);
      }
    } else {
      // netStatus: true 表示的是华为内网登录。
      commit(types.USER_INNERIP, !!response.netStatus);
      errorGetProfileJump(pathArr);
    }
  },

  refreshAuctionCount({ commit }) {
    const url = baseApi.refactorApi.forum.auctionCount;
    baseApi.GET(url, null, env.newApiBaseURL).then(response => {
      if (response.code === errCode.success && response.data) {
        commit(types.USER_MALL_TRADE_UPDATE, response.data);
      }
    });
  },
  refreshIsLogin({ commit }, isLogin) {
    commit(types.USER_ISLOGIN, isLogin);
  },
  buryCode(vx, pageCode) {
    const url = baseApi.refactorApi.post.updateUemData;
    const params = {
      type: 'web',
      pageCode,
      pagePath: location.href,
    };
    baseApi.POST(url, params, env.newApiBaseURL);
  },
  // 获取行业信息
  getIndustryList({ commit }) {
    return new Promise(resolve => {
      if (this.state.industryList && this.state.industryList.length) {
        resolve(this.state.industryList);
      }
      const url = baseApi.refactorApi.user.getCommonIndustry;
      baseApi.GET(url, null, env.newApiBaseURL).then(response => {
        if (response.code === errCode.success && response.data) {
          commit(types.INDUSTRYLIST_UPDATE, othersUtil.listToOptions(response.data, 'industryId', 'industryName', 'subIndustryList'));
          resolve(response.data);
        } else {
          resolve([]);
        }
      });
    });
  },
  // 获取产品领域
  getFieldList({ commit }) {
    return new Promise(resolve => {
      if (this.state.productFieldList && this.state.productFieldList.length) {
        resolve(this.state.productFieldList);
      }
      const url = baseApi.refactorApi.user.getProductField;
      baseApi.GET(url, null, env.newApiBaseURL).then(response => {
        if (response.code === errCode.success && response.data) {
          commit(types.PRODUCTFIELD_UPDATE, othersUtil.listToOptions(response.data, 'tagId', 'tagName', 'subIndustryList'));
          resolve(response.data);
        } else {
          resolve([]);
        }
      });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
