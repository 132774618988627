/*
 * @Author:
 * @Date: 2022-04-15 09:15:09
 * @Description:
 */
export default {
  overCharacters: 'Up to 500 characters.',
  visibleTo: 'Visible To',
  All: 'All',
  onlyMember: 'Members only',
  joiningMode: 'Joining Mode for Carriers',
  freeJoin: 'Free to join',
  applyJoin: 'Apply to join',
  invitedJoin: 'Invited only',
  notAllowedJoin: 'Not allowed to join',
  groupOwnerJoin: 'After Joining Mode is set to Not allowed to join, the group is accessible only to the group owner.',
  passwordJoin: 'Join by Password',
  enable: 'Enable',
  disable: 'Disable',
  setPassword: 'Set Password',
  joinCondition: 'Users Allowed to Join',
  registeredUsers: 'Registered users',
  verifiedUsers: 'Verified users',
  sharing: 'Group Sharing',
  allow: 'Allow',
  forbid: 'Forbid',
  groupBulletin: 'Group Bulletin',
  overTwoCharacters: 'Up to 255 characters.',
  allowed: '允许发求助帖',
  no: 'No',
  yes: 'Yes',
  groupNotice: '圈子广场展示',
  icon: 'Icon',
  exceedImage: 'The image cannot exceed 512 KB, and its resolution will be automatically reduced to 120 x 120 pixels.',
  save: 'Save',
  cancel: 'Cancel',
  notSelected: 'No files selected.',
  section: 'Section',
  group: 'Group',
  upCharacters: 'Up to {WORD_LEN_LIMIT} characters.',
  enterGroup: 'Enter a group name.',
  enterDescription: 'Enter group description.',
  notExceed: 'The image cannot exceed 512 KB.',
  sureInvalid: 'All passwords in use will become invalid. Continue?',
  submitNow: 'The group configuration has not been submitted. Submit it now?',
  passwordDisabled: 'The Join by Password function is not enabled.',
  earnedScores: 'Group created. You earned 5 stature scores.',
  submittedReview: 'The group icon has been submitted for review.',
  enterAllowed: "Letters, digits, and the following special characters are allowed: {'( ) + - _ / & |'}",
  upEightyCharacters: 'Up to 80 characters.',
  upFiftyCharacters: 'Up to 50 characters.',
  securityNotice:
    'For information security purposes, since January 2017, you can modify Group Name, Visible To, Joining Mode, Users Allowed to Join, and other configurations only after being authorized by the platform operation team.',
  memberReview: 'Member Review',
  pendingReview: 'Pending Review',
  reviewHistory: 'Review History',
  batchApprove: 'Batch Approve',
  batchReject: 'Batch Reject',
  all: 'All',
  approved: 'Approved',
  rejected: 'Rejected',
  noPending: 'No members pending review.',
  nickname: 'Nickname',
  email: 'Email',
  account: 'Account',
  applied: 'Applied',
  reason: 'Reason',
  referredBy: 'Referred By',
  source: 'Source',
  operatedBy: 'Operated By',
  message: 'Message',
  operated: 'Operated',
  result: 'Result',
  remark: 'Remarks',
  application: 'Application Information',
  view: 'View',
  details: 'Details',
  sureApprove: 'Approve the applications?',
  sureReject: 'Reject the applications?',
  addRemarks: 'Add Remarks',
  selectMember: 'Select members.',
  memberManagement: 'Member Management',
  enterNickname: 'Enter a member nickname.',
  search: 'Search',
  upMemberNotice: 'Up to {rewardermax} members can be set as award distributors.',
  addMember: 'Add Members',
  lastVisited: 'Last Visited',
  modify: 'Modify',
  add: 'Add',
  delete: 'Delete',
  pleaseEnter: 'Please enter.',
  modificationHistory: 'Modification History',
  historicalRemarks: 'Historical Remarks',
  modified: 'Modified',
  modifiedBy: 'Modified By',
  ok: 'OK',
  awardDistributor: 'Award Distributor',
  selectMembers: 'Select members.',
  online: 'Online',
  starMember: 'Star Member',
  moderator: 'Moderator',
  groupOwner: 'Group Owner',
  sectionAdmin: 'Section Administrator',
  groupAdmin: 'Group Manager',
  pointDistributor: 'Point Distributor',
  topicCategory: 'Topic Category',
  informationAccordingly: 'When the topic category function is enabled, configure the category information accordingly.',
  enableTopic: 'Enable Topic Category',
  needTopic: 'Indicates whether users need to select categories when posting new topics.',
  categorizePosts: 'Forcibly Categorize Posts',
  displayCategory: 'Indicates whether to display category names before topics.',
  preCategory: 'Display Category Name as Prefix',
  notDisplay: 'No',
  display: 'Yes',
  No: 'No.',
  categoryName: 'Category Name',
  associatedTemplate: 'Associated Template',
  forciblyAddTag: 'Forcibly Add Tag',
  addSubcategory: 'Add Subcategory',
  addCategory: 'Add Category',
  enterCategory: 'Enter a category name.',
  duplicateCategories: 'Duplicate categories.',
  duplicateSubcategories: 'Duplicate subcategories.',
  none: 'None',
  deleteSubcategories: 'Delete the topic category and its subcategories?',
  deleteCategories: 'Delete the topic category?',
  groupTransfer: 'Group Transfer',
  transferTitle: 'Transfer conditions:',
  transferConditions: 'You can transfer the group only to one of its administrators. \r\n' + 'The transferee must be qualified to create a group. \r\n',
  transferTitle1: 'Note:',
  transferConditions1:
    'After the group is transferred, you are still a member of the group but are no longer the group owner. Therefore, you can leave the group as you like. \r\n' +
    'The transfer takes effect immediately. Exercise caution when performing this operation.',
  transferGroup: 'Transfer group to',
  continueTransfer: 'Transferring the group to {demiseName} cannot be undone. Continue?',
  selectTransferee: 'Select a transferee.',
  transferredSuccess: 'Group transferred successfully.',
  attachmentReview: 'Attachment Review',
  image: 'Image',
  other: 'Other',
  reviewAll: 'Review All',
  fileName: 'File Name',
  author: 'Author',
  topic: 'Topic',
  size: 'Size',
  downloads: 'Downloads',
  reviewResult: 'Auto-review Result',
  reviewedBy: 'Reviewed By',
  operation: 'Operation',
  approve: 'Approve',
  cancelApproval: 'Cancel Approval',
  deleteAttachment: 'Delete Attachment',
  deleReason: 'Enter the reason for deletion.',
  scanFailed: 'Scan failed',
  toScanned: 'To be scanned',
  scanning: 'Scanning',
  rejectedControl: 'Rejected under risk control review',
  noAttachments: 'No attachments pending review.',
  approveAttachment:
    'The attachment was rejected under risk control review, which means it may have unknown risks. Are you sure you want to approve the attachment?',
  virusScan: 'Virus scan failed',
  reposting: 'Reposting',
  invitedNotice: 'Note: Reposting to other groups is forbidden for the group whose Visible To is set to Members only and Joining Mode to Invited only.',
  repostToOther: 'Repost to Other Groups',
  repostToOtherAppend: '',
  open: 'Enable',
  close: 'Disable',
  repostFromOther: 'Repost from Other Groups',
  repostFromOtherAppend: '',
  pcClient: 'PC Client',
  browse: 'Browse',
  dragUpload: 'Upload a 1200 x 230 image.',
  mobileDragUpload: 'Upload a 375 x 220 image.',
  upload: 'Upload',
  mobileClient: 'Mobile Client',
  redirectionURL: 'Redirection URL',
  enterURL: 'Enter a URL.',
  groupSetting: 'Group Setting',
  groupCover: 'Cover',
  joinMode: 'Joining Mode',
  joinFree: 'Free to join',
  joinApply: 'Apply to join',
  groupName: 'Group Name',
  groupDescription: 'Group Description',
  groupIcon: 'Group Icon',
  plateName: 'Section Name',
  plateDescription: 'Section Description',
  plateIcon: 'Section Icon',
  pendReview: 'Pending Review',
  pass: 'Approved',
  applyReason: 'Reason',
  note: 'Note',
  hasApproved: 'Approved',
  deleteReason: 'Reason',
  KIAScanFailed: 'KIA scan failed',
  addRemark:
    'You can also add remarks for the member, such as the company and name, to facilitate quick identification. You and other administrators of the group can view and modify the remarks in Member Management.',
  accessDenied: 'Access denied. Redirecting to the home page in 5 seconds…',
  clickToRedirect: 'Click here if your browser does not automatically redirect you.',
  applicant: 'applicant',
  inviter: 'inviter',
  information: 'Information',
  confirm: 'Confirm ',
  approval: 'approval',
  rejection: 'rejection',
  thisApplication: ' of this application?',
  joinTime: 'Join Time',
  addDistributors: 'Confirm to add the following award distributors to {groupSpaceName}?',
  distributionType: 'Reward Distribution Type:',
  searchingUsers: 'Searching for Users',
  pleaseEnterContext: 'Please enter.',
  addList: 'Add to List',
  userFound: 'The following is a list of the users found (up to 20 are displayed):',
  username: 'Username:',
  huaweiEmployee: 'Huawei employees',
  noteBefore: 'Note: ',
  forbiddenNote: 'Reposting to other groups is forbidden for the group whose Visible To is set to Members only and Joining Mode to Invited only.',
  pendedReview: 'Pending Review',
  delectAttachment: 'To delete an attachment, the reason for deleting the attachment must be transferred.',
  reviewResultNotice: 'The file requires a system scan, which takes about an hour to complete.',
};
