// 不知道咋分类的工具

//  将多层的数组转为级联选择器的格式
const listToOptions = (list, valueField, labelField, childrenField, deepIndex = 0) => {
  return list.map(item => {
    item.label = item[labelField];
    item.value = item[valueField];
    if (item[childrenField] && item[childrenField].length) {
      item.children = listToOptions(item[childrenField], valueField, labelField, childrenField, deepIndex + 1);
    } else if (deepIndex !== 2) {
      // 第三层不需要children
      item.children = [];
    }
    return item;
  });
};

// 获取行业级联选择器的路径值
function findPath(list, targetId, field, industryId = 'industryId', path = []) {
  const res = [];
  list.find(item => {
    if (item[industryId] === targetId) {
      path.push(item[field]);
      res.push(...path);
      return true;
    }
    if (!res.length && item.children && item.children.length) {
      path.push(item[field]);
      res.push(...findPath(item.children, targetId, field, industryId, path));
      path.pop();
    }
    return false;
  });
  return res.length ? res : [];
}

// 格式化行业，行业仅展示最小级，最小级为‘其他’时，展示上一级
function formatIndustry(industryStr) {
  const list = (industryStr || '').split('|').reverse();
  return list.find(item => item !== '其他') || '';
}

//  将多层的数组转为级联选择器的options格式
const listToCascaderOptions = (list, valueField, textField, childrenField) => {
  return list.map(item => {
    const option = {};
    option.text = item[textField];
    option.value = item[valueField];
    if (item[childrenField] && item[childrenField].length) {
      option.children = listToCascaderOptions(item[childrenField], valueField, textField, childrenField);
    }
    return option;
  });
};

export default {
  listToOptions,
  findPath,
  formatIndustry,
  listToCascaderOptions,
};
