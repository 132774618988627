// 导入所有的 assets/images/jdcSvg svg图片
import SvgIcon from './index.vue';

require
  .context('@/assets/images/jdcSvg', false, /\.svg$/)
  .keys()
  .forEach(svg => {
    import(`@/assets/images/jdcSvg${svg.slice(1)}`);
  });

export default {
  install: app => {
    app.component('SvgIcon', SvgIcon);
  },
};
