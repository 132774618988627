export default {
  discussTitle: '精华讨论-华为JDC 数字化从业者必看的技术干货/华为社区',
  discussDescription:
    '精华讨论是一个为技术专业人士提供的技术干货分享板块。JDC按照行业分类展示了这些高质量的讨论，包括能源、农业、数据通信、云核心网、数据存储、无线、教育、制造、医疗、政务、光技术和新能源汽车等领域。这个平台是专业人士交流思想、分享经验和探讨最新技术趋势的理想场所。深入您的专业领域，与同行精英共同推动行业的技术进步。',
  discussKeywords:
    '华为,JDC,数字化,华为社区,知识社区,精华讨论,专业技术分享,能源,农业,数据通信,云核心网,数据存储,无线,教育,制造,医疗,政务,光技术,新能源汽车技术趋势,行业交流,经验分享,技术创新,知识社区',
  suggestTitle: '提产品建议-华为JDC 提华为产品建议，定义下一代产品/华为社区',
  suggestDescription:
    '提产品建议是给华为产品提建议、提需求的板块。华为产品用户是产品创新的核心，无论是华为无线业务、数字能源业务、数据存储、云核心网、家庭网络WIFI、光产品、数据通信还是智慧园区产品，用户都可以在这里针对使用的产品提出宝贵的建议和改进想法。这个平台是用户声音的直接渠道，华为技术团队积极倾听并响应。对于高价值建议，华为技术团队还会发放智豆作为奖励，鼓励用户的积极参与和贡献。您的每一个建议都可能成为华为产品创新和优化的关键，与华为共同定义下一代产品！',
  suggestKeywords:
    '华为,JDC,数字化,华为社区,知识社区,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,社区积分,技术团队,产品创新,用户体验',
  interactionTitle: '互动讨论-华为JDC 认识更多数字化从业者，广交朋友，一起成长/华为社区',
  interactionDescription:
    '互动讨论是一个自由开放的板块，用户畅所欲言分享和讨论各种主题。在这里，您可以找到“技术干货”，深入探讨专业技术知识和解决方案；您还可以关注“行业动态”，了解行业趋势和前沿动态。此外，“兔粉杂谈”提供了一个轻松的环境，让您和其他用户在这里广交朋友，一起成长。',
  interactionKeywords:
    '华为,JDC,数字化,华为社区,知识社区,互动交流,技术干货,行业动态,自由讨论,专业交流,社区互动,技术知识,解决方案,行业趋势,前沿动态,信息分享,社交平台,智能化,云计算,边缘计算,数字安全,5GtoX,信创,运维简化,大数据,全光网络,算力基础设施,数字化,低碳化,物联网,智能汽车,6G,WiFi7,XR,卫星通讯,超融合,生态建设,IPv6,智慧交通,车联网,鸿蒙,区块链,数字孪生,数字治理,芯片,FTTR,低代码开发,框式交换机,裸眼3D,信号升格,星闪,指挥交通,AI,大模型,新能源汽车,问界',
  defaultTitle: '活动-华为JDC 参与华为技术探讨，赢丰厚好礼/华为社区',
  defaultDescription:
    '参与华为JDC的“有奖话题”活动，赢取丰厚礼品。华为JDC为华为产品用户、行业专家和华为技术团队提供了一个交流的平台，旨在促进知识共享、技术创新。',
  defaultKeywords: '华为,JDC,数字化,华为社区,知识社区,有奖活动,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,新能源汽车',
  askpeersTitle: '问问同行-华为JDC 工作遇到问题，就来问问同行/华为社区',
  askpeersDescription:
    '问问同行板块是数字化从业者问答社区,用户可以提出问题、解决问题、或者搜索其他用户沉淀的精彩内容；在这里可以感受到热烈的互助气氛，浏览到精彩的问答内容，获得对症下药的经验分享，最终提升自身专业技能。此外，“问问同行”也是结交同行业人脉的绝佳平台，让您的专业网络更加宽广。',
  askpeersKeywords:
    '华为,JDC,数字化,华为社区,知识社区,问问同行,电信运营商,政务,教育,教育主管部门,高校,高职,中职,基础教育,科研院所,培训机构,留学/移民中介,学生,医疗,医疗卫生监管机构,医院,社区医疗/卫生院/诊所,康复中心/疗养院,健康体检,检测机构/医学中心,互联网,医药,医疗器械,金融,金融监管机构,银行,证券,保险,互联网金融,制造,汽车,机械/重工,通信设备/电子/3C,冶金,化工,仪器仪表,金属/五金制品,轻工业,建材/家具,医疗器械/设备制造,制药,交通,交通管理/规划设计,航空,铁路,城轨,城市交通,高速公路,水运,海关/口岸,互联网/IT,游戏,电商,生活服务,传媒/资讯/搜索/社交,软件开发/IT系统集成/软件实施,云计算/大数据/人工智能/IDC,电力,电力行业管理机构,发电厂/电力生产,电网/输配电,电力电气设备制造,电力规划设计/建设/运维,矿业,煤矿,金属矿产,水泥,油气,油气行业监管机构,油气勘探/开采,炼油化工,油气储运,成品油气零售,燃气供应,文化/体育/娱乐/传媒,文化体育监管机构,传统媒体,新媒体,影音制作/发行,公共文化/体育场馆,会议展览服务,房地产/建筑,物流/仓储,酒店及餐饮,批发与零,农林牧副渔',
  industryReportsTitle: '行业报告-华为JDC 解读行业趋势,JDC独家行业报告/华为社区',
  industryReportsDescription:
    '行业报告是专业知识和深度的行业洞察的宝库。这里独家分享的每一份行业报告都源自于JDC往期探讨活动中用户的声音和专家的整理，确保了报告的专业性、独家性和高质量。报告覆盖了广泛的行业，包括能源、农业、数据通信、云核心网、数据存储、无线、教育、制造、医疗、政务、光技术和新能源汽车等领域，为行业从业者提供了宝贵的市场分析、趋势预测和战略建议。找高质量行业报告，就上华为JDC行业报告板块。',
  industryReportsKeywords:
    '华为,JDC,数字化,华为社区,知识社区,行业数字化,行业报告,独家报告,高质量报告,免费报告,能源,农业,数据通信,云核心网,数据存储,无线,教育,制造,医疗,政务,光技术,新能源汽车,市场分析,趋势预测,战略建议,业务决策,数据支持,行业洞察',
  openClassTitle: '公开课-华为JDC  独家内容专业嘉宾，洞见行业数字化未来/华为社区',
  openClassDescription:
    '华为JDC公开课板块包含一系列由华为JDC自制的行业数字化专家分享视频，这些视频内容丰富，涵盖了行业趋势、深入的行业洞察、以及各行业在数字化过程中遇到的问题和相应的解决方案。华为邀请的每一位嘉宾都是具有多年行业经验的资深专家，他们在这里为所有社区用户免费提供知识和经验的分享。这是一个学习最新行业知识、提升专业技能和探索数字化未来的绝佳平台。',
  openClassKeywords:
    '华为,JDC,数字化,华为社区,知识社区,华为公开课,行业数字化,专家分享,视频教程,行业趋势,行业洞察,解决方案,资深专家,免费学习,专业技能,行业知识,数字化转型,学习平台,直播,智能化,云计算,边缘计算,数字安全,5GtoX,信创,运维简化,大数据,全光网络,算力基础设施,数字化,低碳化,物联网,智能汽车,6G,WiFi7,XR,卫星通讯,超融合,生态建设,IPv6,智慧交通,车联网,鸿蒙,区块链,数字孪生,数字治理,芯片,FTTR,低代码开发,框式交换机,裸眼3D,信号升格,星闪,指挥交通,AI,大模型,新能源汽车,问界',
};
