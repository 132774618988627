/**
 * @file 添加/编辑备注弹窗 国际化-英文
 */
export default {
  editor: 'Edited By',
  editRemarkMsg: 'Edit/Add Remarks',
  editRemarkTitle: 'Add/Edit Remarks',
  editorTitle: ` (Current operator: {nickname})`,
  tipsTitle: 'Note: ',
  tips: 'Remarks can be seen by all administrators but edited only by users in the trustlisted user group.',
  remarkPlaceholder: 'Enter up to 40 characters',
  remarkNotice: 'Enter up to 40 characters',
  submit: 'Ok',
  cancel: 'Cancel',
};
