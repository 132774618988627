export default {
  settings: 'Settings',
  changeAvatar: 'Change Profile Picture',
  profile: 'Profile',
  privacyAccountSecurity: 'Privacy Settings & Account Security',
  currentAvatar: 'Current Profile Picture',
  uploadedAvatar:
    'If you have not uploaded a profile picture, a default image will be used. You can upload an image that you would like to use as your profile picture.',
  newAvatar: 'New Profile Picture',
  selectImageFormat: 'Select an image in JPG, JPEG, or PNG format.',
  saveRefreshAvatar: 'Your new profile picture will be displayed once it passes review.',
  selectImage: 'Select Image',
  imageFormat: 'The image must be in JPG, JPEG, or PNG format.',
  uploadImage: 'Upload an image.',
  avatarReviewed: 'Your profile picture is being reviewed.',
  basicInformation: 'Basic Information',
  username: 'Username',
  nickname: 'Nickname',
  nicknameNotChanged: 'Once set, your nickname cannot be changed.',
  industry: 'Industry',
  public: 'Public',
  private: 'Private',
  company: 'Company',
  enterDropDown: 'Enter a keyword and select your company from the drop-down list.',
  birthday: 'Birthday',
  month: 'Month',
  date: 'Date',
  occupation: 'Occupation',
  city: 'City',
  personalMotto: 'Personal Signature',
  checkItem: 'Check this item.',
  enterSelectCompany: 'Enter a keyword and select your company from the drop-down list.',
  contactInformation: 'Contact Information',
  otherPrivacyStatement: 'Other users cannot see information on this page. For details about your privacy protection, see the ',
  privacyStateMentNoun: 'Privacy Statement',
  registrationEmail: 'Registration Email',
  enterRegistration: 'Enter an email address for registration.',
  changeEmail: 'Change Email',
  notVerified: 'Not verified',
  sendVerificationCode: 'Send Verification Code',
  resend: 'Resend',
  verified: 'Verified',
  registeredPhoneNumber: 'Registered Mobile Phone Number',
  changePhoneNumber: 'Change Mobile Phone Number',
  contactEmail: 'Contact Email',
  email: 'Email',
  enterEmailAddress: 'Enter an email address.',
  mobilePhoneNumber: 'Mobile Phone Number',
  mobilePhone: 'Mobile Phone',
  enterMobileNumber: 'Enter a mobile phone number.',
  name: 'Name',
  enterRealName: 'Enter your real name.',
  landline: 'Landline',
  enterLandline: 'Enter a landline phone number.',
  checkInformationItem: 'Check this item.',
  Subscription: 'Subscriptions',
  subscribeEmails: 'You can subscribe to or block emails from the community.',
  EmailNotification: 'Email notification',
  PrivacyStatement: 'Privacy Statement',
  acceptPrivacyStatement: 'I accept all terms and conditions in the Privacy Statement.',
  acceptTermsUse: 'I accept all terms and conditions in the Terms of Use.',
  AccountDeletion: 'Account Deregistration',
  informationAccount: 'Read the following information carefully before you deregister your account',
  cannotRecovered: 'Your account cannot be recovered once deregistered.',
  privateDeletedBackNecessary: 'Your private data will be permanently deleted. Back up your data if necessary.',
  relatedPermissions: 'Your level, VIP status, and related permissions will become invalid.',
  pointsRedeemedGifts: 'Points of the account cannot be transferred or redeemed for gifts.',
  redeemedBeenReceived:
    'If you have redeemed your points for gifts and these gifts have not arrived, you are advised to deregister your account after the gifts have been received.',
  deleteAccountOperations: 'To deregister your account, click Deregister Account below and perform operations as prompted.',
  deleteAccount: 'Deregister Account',
  privacySettings: 'Privacy Settings',
  personalizedRecommend: 'Personalized recommendations',
  recommendInteraction: 'Recommend content and community activities based on your interactions.',
  disableRecommendation: 'Disable personalized recommendations?',
  afterReceiveRecommend:
    'After personalized recommendations are disabled, you will not receive personalized recommendations and may miss community activities that you are interested in.',
  keepEnabled: 'Keep Enabled',
  disable: 'Disable',
  operationSucceeded: 'Operation succeeded.',
  enabledSuccessfully: 'Enabled successfully.',
  yourEmail: 'Your email',
  verifiedSuccessfully: 'verified successfully',
  updateRegisteredPhone: 'Update Registered Mobile Phone Number',
  personalPrivacy: 'Personal Privacy',
  privacySort: 'Privacy Filter',
  checkInformation: 'Select an industry.',
  inputCompanyName: 'Enter a company name.',
  inputNChooseName: 'Please enter and choose a company name',
  viewAccountDetails: 'View Account Details',
  thirdPartyAccountInfo: 'Third-party Account Information',
  JDCContactinfo: 'JDC Contact Information',
  verifySuccessful: 'Verified',
  verificationFailed: 'Not verified',
  shareOpinions: 'Share your opinions...',
  verify: 'Verify',
  verification: 'Verification',
  uniportalAccountName: 'Uniportal Account Name',
  weChatAccountName: 'WeChat Account Name',
  emailSentTo: 'The email verification link has been sent to',
  checkEmailVerify: 'please check and verify in time',
  haveVerified: 'I have verified',
  scores: 'Scores',
  settingsKeywords: '',
  settingsDescription: '',
};
