export default {
  privacyStatement: 'Privacy Statement',
  termsOfUse: 'Terms of Use',
  JDCPrivacyStatement: 'JDC Privacy Statement',
  JDCTermsUse: 'JDC Terms of Use',
  huaweiJointDefinitionCommunity:
    'Huawei Joint Definition Community is a knowledge repository where you can share product information, feedback, and suggestions with Huawei product users, industry veterans, and Huawei experts.',
  welcomeFutureHuaweiProducts: "We welcome all suggestions for future Huawei products. Let's define the next generation of products together.",
  agree: 'Agree',
  disagree: 'Disagree',
  agreeTermsPrivacystatement: 'You can use this product and service only after you agree to the terms of use and privacy statement.',
  accessDeniedToHome: 'Access denied. Redirecting to the home page in 5 seconds…',
  and: 'And',
};
