/*
 * @Date: 2024-06-26
 * @Description: h5圈子资源文件
 */
export default {
  myGroups: 'Groups',
  noJoinedGroup: 'You are not currently following any circles',
  all: 'All',
  cancel: 'Cancel',
};
