import zhLocale from 'element-plus/lib/locale/lang/zh-cn';

import activityZh from '../zh/activity';
import adminEscZh from '../zh/adminEsc';
import authorRemarkZh from '../zh/authorRemark';
import ciphertextZh from '../zh/ciphertext';
import circleManageZh from '../zh/circleManage.js';
import commonZh from '../zh/common';
import forumsZh from '../zh/forums';
import groupZh from '../zh/group';
import h5GroupZh from '../zh/h5/group';
import h5Zh from '../zh/h5/h5';
import h5KeywordsZh from '../zh/h5/keywords';
import keyWordsZh from '../zh/keyWords';
import lotteryZh from '../zh/lottery';
import homePageZh from '../zh/modules/homePage';
import newThreadZh from '../zh/newThread';
import postDetailsZh from '../zh/postDetails';
import privacyZh from '../zh/privacy';
import profileZh from '../zh/profile';
import settingZh from '../zh/setting';
import surveyZh from '../zh/survey';
import uploadImageZh from '../zh/uploadImage.js';
import userCenterZh from '../zh/userCenter';
import allTypePostZh from '../zh/userCenter/allTypePost';
import userCenterMessageZh from '../zh/userCenter/messages';
import suggestionsZh from '../zh/userCenter/suggestions';

const zh = {
  lang: {
    adminEsc: adminEscZh,
    ciphertext: ciphertextZh,
    common: commonZh,
    forums: forumsZh,
    group: groupZh,
    h5: h5Zh,
    homePage: homePageZh,
    lottery: lotteryZh,
    newThread: newThreadZh,
    postDetails: postDetailsZh,
    profile: profileZh,
    setting: settingZh,
    privacy: privacyZh,
    activity: activityZh,
    uploadImage: uploadImageZh,
    circleManage: circleManageZh,
    userCenter: userCenterZh,
    suggestions: suggestionsZh,
    allTypePost: allTypePostZh,
    userCenterMessage: userCenterMessageZh,
    keyWords: keyWordsZh,
    h5Group: h5GroupZh,
    h5Keywords: h5KeywordsZh,
    survey: surveyZh,
    authorRemark: authorRemarkZh,
  },
  ...zhLocale,
};
export default zh;
