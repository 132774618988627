/*
 * @Author:
 * @Date: 2022-09-22 20:47:26
 * @Description: h5页面
 */
export default {
  desc: '华为JDC是用户与华为产品专家交流的互动平台，是反馈产品建议和新需求的便捷渠道。',
  login: '手机号/华为账号登录',
  register: '立即注册',
  policy1: '我已阅读并同意',
  userAgreement: '用户协议',
  policy2: '和',
  privacyStatement: '隐私声明',
  agree: '同意',
  disagree: '撤销同意',
  bottomBar1: '首页',
  bottomBar2: '圈子',
  bottomBar3: '消息',
  bottomBar4: '智豆兑换',
  post: '发布图文帖',
  suggest: '提交产品建议',
  updateName: '推荐',
  updated: '已更新',
  noData: '我是有底线的',
  homeTitle: '个人资料',
  avatarUploading: '上传头像中...',
  avatar: '头像',
  verified: '已验证',
  unverify: '未验证',
  nickname: '昵称',
  level: '等级',
  personalSign: '个性签名',
  realName: '姓名',
  industry: '行业',
  company: '公司',
  email: '联系邮箱',
  phoneNumber: '手机',
  myPosts: '我的帖子',
  someMinuteAgo: '{count}分钟前',
  someHoursAgo: '{count}小时前',
  someDaysAgo: '{count}天前',
  someWeeksAgo: '{count}周前',
  end: '我是有底线的',
  noPoint: '暂无内容',
  noContent: '暂无内容',
  imgUploadTip: '头像已提交审核，请耐心等候',
  certifiedUser: '认证用户',
  certifiedInfo: '认证信息',
  administrator: '管理员',
  repliesOnlyToUsers1: '回帖仅作者/管理员/授权专家可见',
  repliesOnlyToUsers2: '回帖仅作者/管理员可见',
  submittedAsRR: '提交RR',
  reward: '悬赏',
  bean: '智豆',
  myReply: '我的回复',
  allReply: '全部回复',
  showMore: '查看更多',
  recommended: '按推荐',
  latest: '按最新',
  earliest: '按时间',
  newPost: '发布',
  posting: '发布中',
  postSuccessful: '发布成功',
  pleaseEnter: '请输入',
  uploading: '上传中',
  enterTitle: '请输入标题',
  enterRequired: '请填写必填项',
  enterContert: '请输入内容',
  postSuccess: '发布成功',
  confirm: '确定',
  title: '标题',
  thinkMore: '取消',
  point: '{count} 智豆',
  cancelBlock: '取消屏蔽',
  copy: '拷贝',
  copySuccessful: '拷贝成功',
  commentSuccess: '评论成功',
  replySuccess: '回复成功',
  enterReply: '请填写回复内容',
  noReply: '暂无回复',
  createReply: '说点什么...',
  topicCategory: '主题分类',
  enterTopicCategory: '请选择主题分类',
  ok: '确认',
  favoritesuccess: '收藏成功',
  cancelFavorite: '取消收藏成功',
  praiseMyself: '不能赞自己',
  showMoreComment: '查看全部{count}条评论',
  noTopics: '暂无帖子',
  author: '作者',
  noSuggestions: '暂无产品建议',
  noEvents: '暂无活动',
  lastVisited: '最近登录',
  nameAlreadyUse: '哎呀，昵称已被使用，换一个试试吧！',
  nameNotUse: '该昵称不可用，试试别的吧',
  enterSign: '请输入个性签名',
  savingFailed: '保存失败',
  sendEmail: '发送验证邮件',
  sendEmailFailed: '发送失败!',
  verificationEmail: '验证邮件已发送到您的邮箱，请登录邮箱完成验证!',
  contactEmail: '邮箱',
  loading: '加载中',
  agreeTitle: '请阅读并同意以下条款',
  agreeDes: '为了更好地保障您的合法权益，请您阅读并同意',
  accpeAndContinue: '同意并继续',
  userAgreementBook: '《用户协议》',
  privacyStatementBook: '《隐私声明》',
};
