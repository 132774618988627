export default {
  stature: 'Stature',
  contributionPoint: 'Contribution Point',
  point: 'Point',
  jdcName: 'Joint Definition Community',
  hwJDC: 'Huawei JDC',
  home: 'Home',
  groups: 'Groups',
  forum: 'Forum',
  events: 'Events',
  suggestions: 'Suggestions',
  questions: 'Questions',
  discussions: 'Discussions',
  general: 'General',
  technical: 'Technical',
  redeemPoints: 'Redeem Points',
  hot: 'Hot',
  all: 'All',
  users: 'Users',
  documents: 'Documents',
  searchTip: 'Enter a keyword.',
  mobile: 'Mobile',
  weChatTry: 'Scan with WeChat',
  settings: 'Settings',
  signIn: 'Sign In',
  managementCenter: 'Management Center',
  logOut: 'Log Out',
  aboutUs: 'About Us',
  jdcExplain:
    'Huawei Joint Definition Community (jdc.huawei.com) is a knowledge repository where you can share product information, feedback, and suggestions with Huawei producusers, industry veterans, and Huawei experts. ',
  quickLinks: 'Quick Links',
  contactUs: 'Contact Us',
  copyRightWord: 'Copyright © Huawei Technologies Co., Ltd.',
  rightsReserved: 'All rights reserved. Guangdong A2-20044005',
  badReport: 'Report inappropriate content',
  privacyStatement: 'Privacy Statement',
  termsofUse: 'Terms of Use',
  gatheringApproving: 'We use cookies to improve our site and your experience. By continuing to browse our site you accept our cookie policy.',
  huaweiJDCMobile: 'Huawei JDC',
  getPoints: 'See your experience points.',
  subscribeTheLatest: 'Subscribe to latest content.',
  shareDigitalApp: 'Share your digitization stories.',
  followJDCOfficial: 'Follow Huawei JDC.',
  theDigitizationForum: 'Explore digital transformation.',
  huaweiOfficial: 'Huawei Corporate',
  technicalSupport: 'Carrier Technical Support',
  enterpriseForum: 'Enterprise Forum',
  huaweiEnterpriseBusiness: 'Huawei Enterprise Business',
  carrierForum: 'Carrier Forum',
  enterpriseTechnicalSupport: 'Enterprise Technical Support',
  huaweiCloudForum: 'Huawei Cloud Forum',
  huaweiTalent: 'Huawei Talent',
  huaweiJDCFindLOGO: 'Huawei JDC–Find the latest digital transformation insights and tailored Huawei products–Huawei JDC',
  huaweiJDCItemsLists:
    'Huawei JDC, Huawei product suggestions, New infrastructure, Carrier, Data center, Storage, Education, Digital energy, Manufacturing, Digital transformation',
  huaweiJDCUsageWords:
    'Huawei Joint Definition Community (JDC) provides a place where industry veterans can communicate with Huawei experts on industry trends and technologiesIndustry veterans are welcomed to define Huawei products and solutions, helping better advance digital transformation across each industry.',
  myGroups: 'My Groups',
  recommendedGroups: 'Recommended Groups',
  noDataAvailable: 'No data available.',
  whatsNew: "What's New",
  newReplies: 'New Replies',
  connectWithExperts: 'Connect with Experts',
  invited2Reply: 'Invited to Reply',
  youWereMentioned: 'You were mentioned',
  noPostsYet: 'No posts yet.',
  column: 'Column',
  new: 'New',
  lastReply: 'Last reply',
  posts: 'Posts',
  followers: 'Followers',
  fields: 'Fields:',
  setNickname: 'Set Nickname',
  settingANickname: 'A nickname makes it easier to remember you.',
  ifDoNotSetting: 'You can also set a nickname later in Settings.',
  nickname: 'Nickname',
  ok: 'Submit',
  cancel: 'Cancel',
  nicknameModified: 'Nickname modified successfully.',
  changeNicknameLater: 'You can change your nickname in the personal center later.',
  completeInformation: 'Complete Information',
  aquireCompletePhone:
    "In accordance with the Cybersecurity Law of the People's Republic of China, real-name authentication is required for using Internet services. Please verifyour identity with your mobile phone number.",
  name: 'Real Name',
  enterName: 'Enter your real name for gift delivery',
  validateName: 'Name limit 0 to 60 characters',
  countryOrRegion: 'Country/Region',
  phone: 'Mobile Phone',
  enterTel: 'Enter your mobile phone number',
  verificationCode: 'Verification Code',
  enteringVerificationCode: 'Enter a 6-digit code',
  getCode: 'Get Code',
  resend: 'Resend',
  sendCode: 'Resend',
  email: 'Email',
  enterEmail: 'Enter your email',
  industry: 'Industry',
  selectRegion: '--Select--',
  company: 'Company',
  enterCompany: 'Enter your company',
  ensureAccurate: 'Ensure that your information is correct, as it will be used for gift delivery.',
  selectIndustry: 'Select an industry.',
  enterCorrectPhone: 'Enter a correct phone number.',
  checkMobile: 'The mobile number is incorrect. Please check.',
  emailFormat: 'Incorrect email address format.',
  phoneFormat: 'Incorrect phone number format.',
  codeCannotEmpty: 'The verification code cannot be empty.',
  enterCorrectCode: 'Please enter the correct verification code.',
  companyLong: 'The company name is too long ({count})',
  enterNickName: 'Enter a nickname.',
  nameLength: 'The nickname can contain only 2 to 24 characters.',
  nameRulesChange: 'The nickname contains special characters. Use another one.',
  nameMaxLength: 'The name is too long ({count})',
  nameRules: 'The name can contain only digits (0-9), lowercase letters (a-z), uppercase letters (A-Z), and periods (.). _ and Chinese!',
  profileDetails: 'Profile Details',
  basicInformation: 'Basic Information',
  curIndustry: 'Industry',
  curCompany: 'Company',
  partnership: 'Partnership',
  consumerInfo: 'Consumer Info',
  activityLevel: 'Activity Level',
  professionalism: 'Professionalism',
  interviews: 'Interviews',
  honors: 'Honors',
  awards: 'Awards',
  userTracking: 'User Tracking',
  userSource: 'User Source',
  userActivities: 'User Activities',
  levelUpStature: 'Current stature scores: {num1}. You need {num2} more scores to level up.',
  showMore: 'Show More',
  showLess: 'Show Less',
  huaweiJointDefinition:
    'Huawei Joint Definition Community (jdc.huawei.com) is a knowledge repository where you can share product information, feedback, and suggestions with Huawei product users, industry veterans, and Huawei experts. ',
  shareUrStories: 'Share your stories.',
  checkIn: 'Check In',
  myProfile: 'My Profile',
  myTasks: 'My Tasks',
  Messages: 'Messages',
  experiencePoints: 'Experience Points',
  WeChat: 'WeChat',
  Notifications: 'Notifications',
  inSiteIssue: 'In-site Issues',
  industryActivities: 'Industry Activities',
  More: 'More',
  startTime: 'Start time',
  Ongoing: 'Ongoing',
  comingSoon: 'Coming soon',
  Bulletin: 'Bulletin',
  mostActiveThisMonth: 'Most Active This Month',
  postNReplies: 'Posts & replies',
  daysActive: 'Days active',
  getStarted: 'Get Started',
  forBeginners: 'For Beginners',
  joinAGroup: 'Join a group',
  proposeProduct: 'Propose product suggestions/requirements',
  discussWithHuawei: 'Discuss with Huawei experts',
  productTeam: 'Product team reviews proposal',
  proposalAccepted: 'Proposal accepted',
  giftRedemption: 'Gift Redemption',
  newGifts: 'New Gifts',
  onlyYYY: 'Only yyy more experience points needed for xxx.',
  providesAPlace:
    'Huawei Joint Definition Community (JDC) provides a place where industry veterans can communicate with Huawei experts on industry trends and technologies. Industry veterans are welcomed to define Huawei products and solutions, helping better advance digital transformation across each industry.',
  recommendedUsers: 'Recommended Users',
  follow: 'Follow',
  following: 'Following',
  peopleFollowing: '{count}Followers',
  Mutual: 'Mutual',
  Information: 'Information',
  unfollowThisUser: 'Unfollow this user?',
  Yes: 'Yes',
  No: 'No',
  topSuggestions: 'Top Suggestions',
  createSuggestion: 'Create Suggestion',
  inviteYou2Reply: 'invited you to reply.',
  OK: 'OK',
  joinGroup: 'Join Group',
  groupsThat: 'Groups that you may like. Join now!',
  Successful: 'Successful',
  joinLater: 'Join Later',
  joinNow: 'Join Now',
  inAccordance:
    "In accordance with the Cybersecurity Law of the People's Republic of China, real-name authentication is required for using Internet services. Please verify your identity with your mobile phone number.",
  Resend: 'Resend',
  enterACompany: 'Enter a company name and select it from the drop-down list.',
  combineAccounts: 'Combine Accounts',
  thisMobilePhone: 'This mobile phone number is already registered to the following account.',
  combinTheAccounts: 'Combine the accounts?',
  Select: 'Select',
  selectOrEdit:
    'Select or edit your personal information (you can also modify it later). This operation will combine posts & replies, activation points, and friend data of the two accounts.',
  pleaseSelect: 'Please select.',
  yesIWant: 'Yes, I want to combine',
  noTheyAre: 'No, they are not my accounts',
  selectTheAccount2Combine: 'Select the account to combine.',
  congratulationsYou: 'Congratulations! You have combined the accounts.',
  notLogged: 'You have not logged in.',
  unable2Cancel: 'Unable to cancel. Accounts were combined.',
  viewProfile: 'View Profile',
  currentActivation: 'Current activation points: xxx. You need xxx more points to level up.',
  myMedal: 'My Medals',
  hisMedal: 'His Medals',
  hasChoosedLabel: 'Selected Tags',
  action: 'Action',
  authenticationType: 'Authentication Type',
  authenticationInformation: 'Authentication Information',
};
