export default {
  cipherTextTitle: 'Ciphertext analysis',
  cipherTextTemplate: 'Download file',
  cipherTextFiles: 'Upload file',
  cipherTextResult: 'Upload result',
  cipherTextMail: 'Email',
  cipherTextPhone: 'Phone Number',
  cipherTextUid: 'Uid',
  cipherTextAddress: 'Address',
  excelThMail: 'email',
  excelThPhone: 'phonenumber',
  excelThUid: 'uid',
  excelThAddress: 'address',
  excelImport: 'Import ',
  excelTemplate: ' parsing template',
  cipherTextPage: 'Template page',
  cipherTextDownload: 'Download file',
  cipherTextDownloadResult: 'Download upload results',
  uploadSuccess: 'Upload success！',
  dataFail: 'Data acquisition failed',
  checkUpload: 'Please check the uploaded Excel!',
  logOut: 'Log Out',
  userAvatar: 'profile picture',
  excel: {
    ciphertextUid: 'Ciphertext uid',
    ciphertextMailbox: 'Ciphertext email',
    ciphertextPhone: 'Ciphertext phonenumber',
    ciphertextAddress: 'Ciphertext address',
    userMailbox: 'email',
    result: 'Result',
    userPhone: 'phonenumber',
    uid: 'uid',
    username: 'username',
    nickname: 'nickname',
    userAddress: 'address'
  }
}
