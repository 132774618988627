import enLocale from 'element-plus/lib/locale/lang/en';

import activityEn from '../en/activity';
import adminEscEn from '../en/adminEsc';
import authorRemarkEn from '../en/authorRemark';
import ciphertextEn from '../en/ciphertext';
import circleManageEn from '../en/circleManage.js';
import commonEn from '../en/common';
import forumsEn from '../en/forums';
import groupEn from '../en/group';
import h5GroupEn from '../en/h5/group';
import h5En from '../en/h5/h5';
import h5KeywordsEn from '../en/h5/keywords';
import keyWordsEh from '../en/keyWords';
import lotteryEn from '../en/lottery';
import homePageEn from '../en/modules/homePage';
import newThreadEn from '../en/newThread';
import postDetailsEn from '../en/postDetails';
import privacyEn from '../en/privacy';
import profileEn from '../en/profile';
import settingEn from '../en/setting';
import surveyEn from '../en/survey';
import uploadImageEn from '../en/uploadImage.js';
import userCenterEn from '../en/userCenter';
import allTypePostEn from '../en/userCenter/allTypePost';
import userCenterMessageEn from '../en/userCenter/messages';
import suggestionsEn from '../en/userCenter/suggestions';

const en = {
  lang: {
    adminEsc: adminEscEn,
    ciphertext: ciphertextEn,
    common: commonEn,
    forums: forumsEn,
    group: groupEn,
    h5: h5En,
    homePage: homePageEn,
    lottery: lotteryEn,
    newThread: newThreadEn,
    postDetails: postDetailsEn,
    profile: profileEn,
    setting: settingEn,
    privacy: privacyEn,
    activity: activityEn,
    uploadImage: uploadImageEn,
    circleManage: circleManageEn,
    userCenter: userCenterEn,
    suggestions: suggestionsEn,
    allTypePost: allTypePostEn,
    userCenterMessage: userCenterMessageEn,
    keyWords: keyWordsEh,
    h5Group: h5GroupEn,
    h5Keywords: h5KeywordsEn,
    survey: surveyEn,
    authorRemark: authorRemarkEn,
  },
  ...enLocale,
};
export default en;
