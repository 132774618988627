import { windowOpen } from '@/utils/common/CommonUtils';
import { IS_OCEANCLUB, IS_OVERSEAS } from '@/utils/web/constant';

const router = {
  name: 'router',
  // 请求时对URL的处理数组方法，得到location和lang等数据信息。
  getApp(base) {
    const locationArr = ['jdc', 'enterprise', 'insight', 'gs', 'carrier'];
    const baseArr = base.toLowerCase().split('?')[0].split('/');
    let str = 'jdc';
    for (let i = 0; i < locationArr.length; i++) {
      const index = baseArr.indexOf(locationArr[i]);
      if (index !== -1) {
        str = baseArr[index];
        break;
      }
    }
    return str;
  },
  getHWConnect(base) {
    const HWConnect = 'huaweiconnect';
    const baseArr = base.toLowerCase().split('/');
    const index = baseArr.indexOf(HWConnect);
    return index !== -1 ? 'huaweiconnect' : '';
  },
  // route.js 路由内的跳转
  baseRoute(routerSuffix) {
    const baseRouter = '';
    return baseRouter + routerSuffix;
  },
  // 获取当前浏览器，localstorage，url中最终的lang
  getLang(base, localStorageLang) {
    if (IS_OVERSEAS) {
      return 'en';
    }
    const langArr = ['zh', 'en', 'ru', 'es', 'fr'];
    const baseArr = base.toLowerCase().split('?')[0].split('/');
    let lang = this.getApp(base) === 'jdc' ? 'zh' : 'en';
    for (let i = 0; i < langArr.length; i++) {
      const index = baseArr.indexOf(langArr[i]);
      if (index !== -1) {
        lang = baseArr[index];
        break;
      }
    }
    if (!lang) {
      // 浏览器中没有lang语言标识的处理方案。只对企业做处理
      if (this.app === 'enterprise') {
        const sysLang = (navigator.systemLanguage ? navigator.systemLanguage : navigator.language).substr(0, 2);
        if (localStorageLang) {
          lang = localStorageLang;
        } else {
          lang = sysLang;
        }
      }
    }
    return lang;
  },
  // 获取404页面的lang, 路由上没有取query参数
  getQueryLang(base) {
    const langArr = ['zh', 'en', 'ru', 'es', 'fr'];
    const baseArr = base.toLowerCase().split('?')[0].split('/');
    let lang = this.getApp(base) === 'jdc' ? 'zh' : '';
    for (let i = 0; i < langArr.length; i++) {
      const index = baseArr.indexOf(langArr[i]);
      if (index !== -1) {
        lang = baseArr[index];
        break;
      }
    }
    return lang;
  },
  getParameterByName(name, url) {
    name = name.replace(/[\\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  /**
   * 替换地址栏全部参数
   * @param query
   * @param params
   */
  replaceRouteQuery(query, params) {
    let href = window.location.href;
    const origin = href.split('?')[0];

    const routeObj = Object.assign({}, params);

    let isFirst = true;
    let str = '';
    Object.keys(routeObj).forEach(key => {
      str += `${isFirst ? '?' : '&'}${key}=${routeObj[key]}`;
      isFirst = false;
    });

    href = `${origin}${str}`;
    if (window.history) {
      // 支持History API
      window.history.replaceState(null, '', href);
    }

    Object.assign(query, params);
  },

  /**
   * @description 获取当前的路由配置
   */
  async getCurrentRouter() {
    let routerModule;
    if (IS_OCEANCLUB) {
      routerModule = await import('@/router/oceanClub');
    } else {
      routerModule = await import('@/router/refactorJdc');
    }

    return routerModule.default;
  },

  /**
   * @description 获取router 基础地址，存储为空， JDC为 /refactor
   * @returns {string}
   */
  getBaseRouterUrl() {
    return IS_OCEANCLUB ? '' : '/refactor';
  },

  /**
   * @description 获取window.href基础地址，存储为空， JDC为 /jdc/refactor
   * @returns {string}
   */
  getBaseHrefUrl() {
    return IS_OCEANCLUB ? '' : '/jdc/refactor';
  },
  /**
   * @description  海外JDC需要完善用户信息时，需要跳转到完善用户信息页面
   */
  toCompletePages() {
    const openHref = `${this.getBaseHrefUrl()}/overSeasComplete`;
    if (window.location.pathname !== openHref) {
      const currentHref = encodeURIComponent(window.location.href);
      windowOpen(`${openHref}?redirect=${currentHref}`, '_self');
    }
  },
};
export default router;
