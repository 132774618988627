export default {
  messages: 'Messages',
  myMessages: 'My Messages',
  noMessages: 'No messages.',
  showMore: 'Show More',
  send: 'Send',
  emptyMessagesCannotBeSent: 'Empty messages cannot be sent.',
  emojis: 'Emojis',
  images: 'Images',
  notifications: 'Notifications',
  myPosts: 'My Posts',
  systemNotifications: 'System Notifications',
  verificationNotifications: 'Verification Notifications',
  posts: 'Posts',
  comments: 'Comments',
  mentions: 'Mentions',
  duplicateNotificationIsIgnored: '{count} duplicate notification is ignored.',
  showLess: 'Show Less',
  noNotifications: 'No notifications.',
  mvp: "Congrats. You're eligible to be a most valuable professional (MVP).",
  clickHereToVerifyYour: 'Click here to verify your account.',
  mVPVerification: 'MVP Verification',
  mVPPrivileges: 'MVP Privileges',
  changeProfilePicture: 'Change Profile Picture',
  anMVPCanGetA: 'An MVP can get a customized picture frame.',
  fieldsOfExpertise: 'Fields of Expertise',
  industries: 'Industries',
  products: 'Products',
  solutions: 'Solutions',
  others: 'Others',
  addFields: 'Add Fields',
  title: 'Title',
  storageExpert: 'e.g.: storage expert',
  profile: 'Profile',
  describeYourExpertiseAndSkills: 'Describe your expertise and skills in detail to provide reference for verification.',
  statements: 'Statements',
  usersMustComply:
    '1. Users must comply with JDC Terms of Use and sign the JDC Privacy Statement. If any violation is found, JDC has the right to revoke MVP verification or privileges at any time and users shall bear all losses incurred therefrom.',
  onlyRepresents: "2. This verification only represents JDC' commendation to users who have made outstanding contributions to the community.",
  verificationIsFree: '3. This verification is free. Apply through the official invitation channel to avoid potential losses.',
  jdcReservesTheRight: '4. JDC reserves the right to interpret this verification.',
  iHaveReadAndAgree: 'I have read and agree to the statement.',
  submit: 'Submit',
  applicationSubmitted: 'Application Submitted',
  submittedSuccessfully: 'Submitted Successfully',
  yourApplicationWillBeProcessed:
    'Your application will be processed within three working days. Once verified, you will have new privileges and a dedicated picture frame.',
  oK: 'OK',
  badgeOfHonor: 'Badge of honor',
  primePictureFrame: 'Prime picture frame',
  beAColumnistInJDC: 'Be a columnist in JDC',
  attendJDCLectures: 'Attend JDC lectures',
  participateJDCOfflineActivities: 'Participate JDC offline activities',
  redeemFewerPointsForGifts: 'Redeem fewer points for gifts',
  enterYourTitle: 'Enter your title.',
  enterYourProfile: 'Enter your profile.',
  selectAtLeastOneField: 'Select at least one field of expertise.',
  yourApplicationIsUnderReview: 'Your application is under review.',
  yourVerifiedPass: "You've been verified as an MVP.",
  yourApplicationHasBeenRejected: 'Your application has been rejected.',
  youAreNotInvited: 'You are not invited.',
};
