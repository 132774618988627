/**
 * @file 问卷模块国际化，中文
 */
export default {
  single: '单选题',
  multiple: '多选题',
  orderRank: '多选排序题',
  paragraph: '文本题',
  shortAnswer: '填空题',
  minSelect: '（必选{value}项）',
  other: '其它',
  submit: '提交问卷',
  submited: '已提交',
  expired: '已过期',
  pleaseSpecify: '请输入...',
  uploadImgTips: '上传图片',
  required: '这个空必填',
  joinGroupTip: '成功加圈方可提交问卷',
  submitting: '提交中...',
  // 国内版暂时没有提示语
  openPlaceholder: '',
  upload: '上传图片',
  uploadTips: '只能上传JPG/JPEG/PNG/GIF文件，最多支持上传9张图片',
  answerShortTips: '时间提示 用户答题时间{time}，较平均时间快{limit}%。本帖用户平均回答用时{averageTime}',
  answerTips: '时间提示 用户答题时间{time}',
  questionIsMultiAnswer: '题目{num}是多选题（必选{size}项），请完成后提交！',
  questionIsMultiAnswerWithOne: '题目{num}是多选题（必选{size}项），请完成后提交！',
  questionMultiOnlyAnswer: '题目{num}是多选题（只选{size}项），请完成后提交！',
  questionBlank: '题目{num}是必填项，请完成所有填空内容！',
  questionNotComplete: '题目{num}必填项未完成，请完成后做答~',
  pollExpired: '话题已过期，去看看别的吧',
  alreadySubmitted: '你已参与过此话题，不可重复提交',
  luckyDrawChance: '哇哦，恭喜获得抽奖机会',
  drawNow: '立即抽奖',
  notComplete: '题目{num}必填项未完成',
  fillBlanks: '，请完成填空内容',
};
