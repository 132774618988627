export default {
  eventStatus: 'Event Status',
  searchByKeyword: 'Search by keyword.',
  getLuckyDrawChances: 'Get lucky draw chances',
  toBeAwarded: 'To be awarded',
  viewAward: 'View Award Issuing Post',
  all: 'All',
  finished: 'Finished',
  ongoing: 'Ongoing',
  awarded: 'Awarded',
  upcoming: 'Upcoming',
  noMoreData: 'No more data.',
  noMatch: 'No match.',
  overMaximumLength: 'The maximum parameter length has been exceeded.',
  allEventTips: 'Huawei JDC Events Reward Topics_Official Events_Event Sign-ups_Event Awards_Live Streams–Huawei JDC',
  enterEventsTips:
    'Huawei JDC, Event Sign-ups, Online Events, Offline Events, Exhibitions, Event Platforms, Activation Points, Experience Points, Huawei Mobile Phones, Tablets, Laptops, Wearables',
  shareYourVoice: 'Join our online discussions or sign up for offline events at Huawei JDC and share your voice on digital transformation.',
  loading: 'Loading...',
  officialEvents: 'Official Events',
  allStatus: 'All',
  noActivity: 'No activity',
  noRelatedFound: 'No related events found.',
  noParticipated: 'You have not participated in the activity.',
  getInvolved: 'Get involved.',
};
