export default {
  suggestions: 'Suggestions',
  settings: 'Settings',
  edit: 'Edit',
  descriptionCannotBeLeftBlank: 'Description cannot be left blank.',
  raiseProductSuggestionsToWin:
    'Raise product suggestions to win points. Note that suggestions on JDC community should be raised in the In-site Issues section.',
  postsInThisSection: 'Posts in this section',
  hot: 'Hot',
  latestReplies: 'Latest Replies',
  latestTopics: 'Latest Topics',
  featuredSuggestions: 'Featured Suggestions',
  "today'sNewPosts:": "Today's New Posts:",
  "today'sReplies:": "Today's Replies:",
  eventAnnouncements: 'Event Announcements',
  productSuggestions: 'Product Suggestions',
  onlookers: 'Observe',
  blockSettings: 'Section Settings',
  administratorSettings: 'Administrator Settings',
};
