export default {
  singlechoiceVote: 'Single-choice vote',
  multichoiceVote: 'Multi-choice vote',
  maxOf2: '(Max of 2)',
  voteToSeeResults: 'Vote to see results',
  '如果xxx为1：xxxVoter': '如果xxx为1：xxx voter',
  viewVoters: 'View voters',
  'votingEndsIn:': 'Voting ends in:',
  votingEnded: 'Voting ended.',
  '5Votes': '5 votes',
  thePollHasClosedOr: 'The poll has closed or expired.',
  submit: 'Submit',
  thisIsAPublicPoll: '(This is a public poll. Others can view your votes.)',
  youHaveAlreadyVotedThank: 'You have already voted. Thank you for voting.',
  youUserGroupDoesNot: 'You user group does not allow users to vote.',
  noVotingData: 'No voting data.',
  selectAtLeastOneOption: 'Select at least one option.',
  onlyGroupMembersCanSubmit: 'Only group members can submit answers.',
  question1IsAMultipleanswer: 'Question 1 is a multiple-answer question (xx options are required). Please answer the question correctly and then submit.',
  'sorry,ButThisPollHas': 'Sorry, but this poll has expired. Check out some other ones.',
  youHaveAlreadySubmittedYour: 'You have already submitted your answers.',
  congratulationsYouHaveEarnedA: 'Congratulations! You have earned a lucky draw chance.',
  goDrawNow: 'Go Draw Now',
  viewOriginalPost: 'View Original Post',
  winners: 'Winners',
  '如果xx为1：xxWinnerInTotal;Xx': '如果xx为1：xx winner in total; xx winner has claimed the prizes.',
  grant50Points: 'Grant 50 points.',
  claimed: 'Claimed',
  prizeInformation: 'Prize information',
  "you'reAWinner": "You're a winner!",
  claimYourPrizeWithinXxx: 'Claim your prize within xxx.',
  youWonAnAward: 'You won an award - make your acceptance speech!',
  "youHaven'tCommentedYet": "You haven't commented yet.",
  youHavePublishedYourAcceptance: 'You have published your acceptance speech.',
  weHaveFoundTheseGroups: 'We have found these groups.',
  '如果xxx为1：WeHaveFoundXxxRelated': '如果xxx为1：We have found xxx related topic.',
  '如果xxx为1：xxxReply': '如果xxx为1：xxx reply',
  '如果xxx为1：view': '如果xxx为1：view',
  weHaveFoundTheFollowing: 'We have found the following users (up to 100 users are displayed).',
  noMatchingResults: 'No matching results.',
  searchKeywords: 'Search keywords',
  'searchUsername/nickname': 'Search username/nickname',
  enterTwoOrMoreCharacters: 'Enter two or more characters.',
  privacyStatement: 'Privacy Statement',
  termsOfUse: 'Terms of Use',
  jDCPrivacyStatement: 'JDC Privacy Statement',
  jDCTermsOfUse: 'JDC Terms of Use',
  huaweiJointDefinitionCommunityIs:
    'Huawei Joint Definition Community is a knowledge repository where you can share product information, feedback, and suggestions with Huawei product users, industry veterans, and Huawei experts.',
  weWelcomeAllSuggestionsFor: "We welcome all suggestions for future Huawei products. Let's define the next generation of products together.",
  agree: 'Agree',
  disagree: 'Disagree',
  youCanUseThisProduct: 'You can use this product and service only after you agree to the terms of use and privacy statement.',
  congratulationsYouEarnedTheXxx: 'Congratulations! You earned the xxx.',
  redeemForYourPrize: 'Redeem for your prize.',
  tag: 'Tag',
  enterATag: 'Enter a tag.',
  noRelatedPostsWereFound: 'No related posts were found. Try other tags or return to the home page.',
  tagNotFound: 'Tag not found.',
  topicName: 'Topic name',
  reward: 'Reward',
  rewardInfo: '{value} as reward',
  price: 'Price',
  resolved: 'Resolved',
  images: 'Images',
  author: 'Author',
  posted: 'Posted',
  replies: 'Replies',
  views: 'Views',
  productExpertStatistics: 'Product expert statistics',
};
