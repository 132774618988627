export default {
  header: {
    quit: '退出',
    title: '企业社区数据管理中心'
  },
  report: {
    statManageCenter: '数据管理中心',
    userActiveData: '用户活跃数据',
    postReplyData: '发回帖数据',
    userStat: '用户数据统计',
    restainStat: '留存数据统计',
    pvView: '浏览量',
    askHelpOverview: '求助帖概览',
    askHelpDetails: '求助帖数据',
    filterData: '数据筛选',
    lang: '语言',
    timeType: '时间类型',
    time: '时间',
    to: '至',
    startTime: '开始日期',
    endTime: '结束日期',
    selectMonth: '选择月',
    userRole: '用户角色',
    area: '地区部',
    country: '国家',
    userCondition: '用户条件',
    onlineTime: '在线时长',
    maxMinutes: '最大值(分钟)',
    minMinutes: '最小值(分钟)',
    minutes: '分钟',
    searchResult: '查询结果',
    dimensionShow: '按维度显示',
    export: '导出表格',
    userActiveNum: '用户活跃量',
    userActiveActualNum: '用户活跃数',
    userNum: '用户数',
    selectTime: '请选择时间',
    interval: '区间',
    singleMonth: '单月',
    firstForums: '父版块',
    secondForums: '子版块',
    hwEmployee: '华为内部员工',
    partner: '渠道伙伴',
    customer: '产品客户',
    normalUser: '普通用户',
    timeTipsMsg: '最大在线时长需大于最小在线时长!',
    selectOnlineTime: '请输入在线时长',
    selectDimensionTips: '请选择维度',
    sum: '合计',
    recentWeek: '最近一周',
    recentMonth: '最近一个月',
    recentThreeMonth: '最近三个月',
    postReplyPic: '发回帖曲线图',
    postReplyNum: '发回帖数',
    posts: '发帖数',
    views: '浏览量',
    replies: '回帖数',
    dataStatPic: '用户数据曲线图',
    userData: '用户数据',
    userTotal: '用户总量',
    userAdd: '用户增量',
    userRetainedStat: '用户留存数据',
    retainedRate: '留存率',
    userRetainedRate: '用户留存率',
    retainedPeriod: '留存周期',
    userRetainedCycle: '用户留存周期',
    averageOnlineTime: '平均浏览时长',
    noExportData: '暂无可导出的数据!',
    selectCondition: '请选择用户条件',
    userOperator: '用户操作查询',
    askForHelp: '求助帖',
    account: '账号',
    nickName: '昵称',
    likes: '点赞数',
    comments: '评论数',
    bestAnswers: '被设置成最佳答案数',
    recommendedAnswers: '被设置成推荐答案数',
    businessReport: '业务量曲线',
    action: '动作',
    actionCount: '动作量',
    date: '日期',
    chooseDateTip: '请选择日期',
    businessReportPreview: '24H业务量数据',
    businessReportData: '24H业务量数据',
    today: '今天',
    yesterday: '昨天',
    aWeekAgo: '一周前',
    askHelpNum: '求助帖数',
    closeCircleNum: '闭环量',
    twoHourReplyRate: '2H及时回复率',
    twoDayCloseCircleRate: '48H闭环率',
    weekCloseCircleRate: '7天闭环率'
  }
}
