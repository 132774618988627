export default {
  header: {
    quit: 'Quit',
    title: 'Enterprise community data management center'
  },
  report: {
    statManageCenter: 'Data management center',
    userActiveData: 'User active data',
    postReplyData: 'Post & reply data',
    userStat: 'User data statistics',
    restainStat: 'Retained data statistics',
    pvView: 'PV-views',
    askHelpOverview: ' Overview',
    askHelpDetails: ' Details',
    filterData: 'Data screening',
    lang: 'Language',
    timeType: 'Time type',
    time: 'Time',
    to: 'To',
    startTime: 'Start date',
    endTime: 'End date',
    selectMonth: 'Select month',
    userRole: 'User role',
    area: 'Region',
    country: 'Country',
    userCondition: 'User condition',
    onlineTime: 'Online Time',
    maxMinutes: 'Max(minute)',
    minMinutes: 'Min(minute)',
    minutes: 'Minutes',
    searchResult: 'Search result',
    dimensionShow: 'Display by dimension',
    export: 'Export form',
    userActiveNum: 'User activity',
    userActiveActualNum: 'User activity number',
    userNum: 'User number',
    selectTime: 'Please select time',
    interval: 'Interval',
    singleMonth: 'Single month',
    firstForums: 'Parent forum',
    secondForums: 'Sub-forum',
    hwEmployee: 'Huawei internal staff',
    partner: 'Channel partner',
    customer: 'Product customer',
    normalUser: 'General user',
    timeTipsMsg: 'Maximum online duration needs to be greater than the minimum online duration!',
    selectOnlineTime: 'Please input online time',
    selectDimensionTips: 'Please select a dimension',
    sum: 'Total',
    recentWeek: 'Last week',
    recentMonth: 'Last month',
    recentThreeMonth: 'Last three months',
    postReplyPic: 'Post & reply graph',
    postReplyNum: 'Post & reply number',
    posts: 'Posts',
    views: 'Views',
    replies: 'Replies',
    dataStatPic: 'User data graph',
    userData: 'User data',
    userTotal: 'Total user',
    userAdd: 'User increment',
    userRetainedStat: 'User retention data',
    retainedRate: 'Retention rate',
    userRetainedRate: 'User retention rate',
    retainedPeriod: 'Retention period',
    userRetainedCycle: 'User retention period',
    averageOnlineTime: 'Average browsing time',
    noExportData: 'No exportable data yet!',
    selectCondition: 'Please select user conditions',
    userOperator: 'User Operations',
    askForHelp: 'Ask For Help',
    account: 'Account',
    nickName: 'Nickname',
    likes: 'Likes',
    comments: 'Comments',
    bestAnswers: 'Best Answers',
    recommendedAnswers: 'Recommended Answers',
    businessReport: 'Business Report',
    action: 'Action',
    actionCount: 'Action Number',
    date: 'Date',
    chooseDateTip: 'Please choose the date',
    businessReportPreview: '24H Business Overview',
    businessReportData: '24H Business Details',
    today: 'Today',
    yesterday: 'Yesterday',
    aWeekAgo: 'A week ago',
    askHelpNum: 'Number of ask for help',
    closeCircleNum: 'Closed loop rate',
    twoHourReplyRate: '2H timely response rate',
    twoDayCloseCircleRate: '48H closed loop rate',
    weekCloseCircleRate: '7-day closed loop rate'
  }
}
