export default {
  settings: '设置',
  changeAvatar: '修改头像',
  profile: '个人资料',
  privacyAccountSecurity: '隐私筛选与账号安全',
  currentAvatar: '当前我的头像',
  uploadedAvatar: '如果您还没有设置自己的头像，系统会显示为默认头像，您需要自己上传一张新照片来作为自己的个人头像',
  newAvatar: '设置我的新头像',
  selectImageFormat: '请选择一个JPG、JPEG、PNG格式的新照片进行上传编辑。',
  saveRefreshAvatar: '头像更改并审核通过后，将在本页面展示最新头像',
  selectImage: '选择图片',
  imageFormat: '上传头像图片只能是 JPG、JPEG、PNG格式!',
  uploadImage: '请先上传图片!',
  avatarReviewed: '头像已提交审核，请耐心等候',
  basicInformation: '基本资料',
  username: '用户名',
  nickname: '昵称',
  nicknameNotChanged: '昵称设置后不可修改',
  industry: '行业',
  public: '公开',
  private: '保密',
  company: '公司',
  enterDropDown: '请输入并选择公司名称',
  birthday: '生日',
  month: '选择月份',
  date: '选择日期',
  occupation: '职位',
  city: '所在地区',
  personalMotto: '个人签名',
  checkItem: '请检查该资料项',
  enterSelectCompany: '请输入并选择公司名称',
  contactInformation: '联系方式',
  otherPrivacyStatement: '本页面信息皆为隐藏内容，不会对其他用户展示。关于隐私保护的更多内容请查看',
  privacyStateMentNoun: '《隐私声明》',
  registrationEmail: '注册邮箱',
  enterRegistration: '请输入注册邮箱',
  changeEmail: '修改邮箱',
  notVerified: '未验证',
  sendVerificationCode: '发送验证',
  resend: '重新发送',
  verified: '已验证',
  registeredPhoneNumber: '注册手机',
  personalPrivacy: '个人资料',
  privacySort: '隐私筛选',
  changePhoneNumber: '修改手机',
  contactEmail: '联系邮箱',
  email: '邮箱',
  enterEmailAddress: '请输入联系邮箱',
  mobilePhoneNumber: '手机',
  mobilePhone: '手机',
  enterMobileNumber: '请输入手机',
  name: '姓名',
  enterRealName: '请输入真实姓名',
  landline: '固定电话',
  enterLandline: '请输入固定电话',
  checkInformationItem: '请检查该资料项',
  Subscription: '订阅设置',
  subscribeEmails: '您可以订阅或屏蔽整个社区的邮件',
  EmailNotification: '邮件提醒：',
  PrivacyStatement: '隐私声明签署',
  acceptPrivacyStatement: '我接受隐私声明中的所有条款',
  acceptTermsUse: '我接受用户协议中的所有条款',
  AccountDeletion: '账号注销',
  informationAccount: '账号注销前请【务必】仔细阅读 账号注销 的提示',
  cannotRecovered: '社区账户注销后不可恢复',
  privateDeletedBackNecessary: '你的私有数据将被永久删除，建议做好备份',
  relatedPermissions: '账户等级、VIP身份将失效，无法继续享受相关权益',
  pointsRedeemedGifts: '账户智豆不可转移，无法继续兑换礼品',
  redeemedBeenReceived: '将影响已兑换的未发货的智豆兑换礼品收货，无法进行订单状态查询，请确认收货后注销',
  deleteAccountOperations: '如您确定删除，请点击账号注销，按提示操作即可',
  deleteAccount: '账号注销',
  privacySettings: '隐私设置',
  personalizedRecommend: '个性化推荐',
  recommendInteraction: '向我推荐适合我的个性化内容及社区活动',
  disableRecommendation: '确认关闭该推荐？',
  afterReceiveRecommend: '关闭后，将无法看到个性化推荐内容，可能错过你感兴趣的社区活动，建议开启',
  keepEnabled: '保持开启',
  disable: '关闭',
  operationSucceeded: '操作成功',
  enabledSuccessfully: '开启成功',
  yourEmail: '您的邮箱',
  verifiedSuccessfully: '验证成功',
  updateRegisteredPhone: '更新注册手机号',
  checkInformation: '请检查该资料项',
  inputCompanyName: '请输入公司名称',
  inputNChooseName: '请输入并选择公司名称',
  viewAccountDetails: '查看账户详情',
  thirdPartyAccountInfo: '第三方账户信息',
  JDCContactinfo: 'JDC联系信息',
  verifySuccessful: '已验证',
  verificationFailed: '未验证',
  shareOpinions: '说点什么...',
  verify: '验证',
  verification: '验证',
  uniportalAccountName: 'Uniportal账户名称',
  weChatAccountName: '微信账户名称',
  emailSentTo: '邮件验证链接已发送至',
  checkEmailVerify: '请及时查看并进行验证',
  haveVerified: '我已验证',
  scores: '积分数',
  settingsKeywords: '设置,个人资料,修改头像,账号安全,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  settingsDescription: '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
};
