export default {
  shippingAddress: 'Shipping Address',
  completeInformation: 'Complete Information',
  perTime: ' Per Time',
  isLogin: 'Log in to your account.',
  record: 'Lucky Draw Record',
  profile: 'Profile',
  hitRecord: 'Winner Records',
  rules: 'Rules',
  lottery: 'Lucky draw',
  lotteryNow: 'Free Chance | Free chances',
  last: '',
  chance: ' chance left',
  chances: ' chances left',
  exploreNotSupport: 'Sorry, your browser does not support this function.',
  opportunity: '',
  timesCost: 'Points Used Per Draw',
  lastAviable: 'Points Available',
  perLotteryLast: 'Chances Left',
  freeTimes: 'Free Chances',
  contribute: 'Contribution Points',
  experience: 'Experience Points',
  perDayCirce: 'Chances Per Day',
  perLotteryCirce: 'Chances in Period',
  name: 'Name',
  phone: 'Mobile Number',
  address: 'Address',
  email: 'Email',
  company: 'Company Name',
  industry: 'Industry',
  lotteryTips: 'The information provided will be used for communication and delivery purposes only. We are committed to protecting personal data.',
  lotteryEnd: 'This event has ended!',
  lotteryNotStart: "Lucky draw hasn't started yet",
  lotteryStart: 'Coming soon ',
  exchange: 'Redeem',
  redeem: 'Redeem',
  exchanged: 'Redeemed',
  exchangeTips: 'After you click OK, the gift will be considered redeemed. Continue?',
  confirmExchange: 'Redeem for your gift',
  exchangeSuccess: 'Redeemed successfully.',
  noLotteryTimes: "You've used all your lucky draw chances today. Come again some other time.",
  congradulate: 'Congratulations!',
  hit: ' won ',
  limitHw: 'Sorry, but you are a Huawei employee and cannot participate in the lucky draw.',
  phoneWrongTips: 'Invalid mobile number.',
  emailWrongTips: 'Enter a valid email address.',
  refreshSuccess: 'Updated successfully.',
  miss: 'You came so close to winning a gift!',
  sorry: 'Better Luck Next Time!',
  recordCheck: 'See your lucky draw record',
  congradulateHit: 'Congrats!',
  get: 'You Won',
  congrats: 'Congrats! You Won {title}',
  congratulation: 'Congratulation!',
  lotteryKeywords: '',
  lotteryDescription: '',
};
