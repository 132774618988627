export default {
  stature: '经验',
  contributionPoint: '贡献',
  point: '智豆',
  jdcName: '产品定义社区',
  hwJDC: '华为JDC',
  home: '首页',
  groups: '圈子',
  forum: '版块',
  events: '活动',
  suggestions: '提产品建议',
  questions: '问问同行',
  discussions: '讨论',
  general: '互动讨论',
  technical: '精华讨论',
  redeemPoints: '智豆兑换',
  hot: '热帖',
  all: '综合',
  users: '用户',
  documents: '文档',
  searchTip: '请输入搜索内容',
  mobile: '移动端',
  weChatTry: '微信扫码体验',
  settings: '设置',
  signIn: '登录',
  managementCenter: '管理中心',
  logOut: '退出',
  aboutUs: '关于我们',
  jdcExplain:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为专家之间交流的互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  quickLinks: '快速链接',
  contactUs: '联系我们',
  copyRightWord: '版权所有 © 华为技术有限公司',
  rightsReserved: '保留一切权利。粤A2-20044005号',
  badReport: '违法和不良信息举报',
  privacyStatement: '隐私声明',
  termsofUse: '用户协议',
  gatheringApproving: '我们使用cookies确保您的高速浏览体验，继续浏览本站，即表示您同意我们使用cookies。',
  huaweiJDCMobile: '华为JDC移动端',
  getPoints: '获取智豆变更动态',
  subscribeTheLatest: '订阅最新有奖话题活动',
  shareDigitalApp: '分享数字化行业应用',
  followJDCOfficial: '关注华为JDC官方视频号',
  theDigitizationForum: '做行业数字化的人都在用的技术交流社区',
  huaweiOfficial: '华为官网',
  technicalSupport: '运营商技术支持',
  enterpriseForum: '企业互动社区',
  huaweiEnterpriseBusiness: '华为企业业务官网',
  carrierForum: '运营商互动社区',
  enterpriseTechnicalSupport: '企业技术支持',
  huaweiCloudForum: '华为云社区',
  huaweiTalent: '华为学习云',
  huaweiJDCFindLOGO: '华为JDC -聊行业数字化 参与华为产品定义-华为JDC',
  huaweiJDCItemsLists: '华为JDC,华为产品建议,新基建,运营商,数据中心,存储,教育,数字能源,制造行业数字化解决方案',
  huaweiJDCUsageWords:
    '华为JDC（华为产品定义社区） 是外部行业专家与华为专家围绕行业特点和关键技术进行交流、互动的平台，让行业专家可以直接参与华为产品/解决方案的定义中来，从而推动关键行业产品/解决方案规划和行业整体数字化的发展。',
  myGroups: '已加入的圈子',
  recommendedGroups: '推荐圈子',
  noDataAvailable: '暂无数据',
  whatsNew: '最新动态',
  newReplies: '有新的回复',
  connectWithExperts: '专家互动',
  invited2Reply: '邀请回答',
  youWereMentioned: '有人@了你',
  noPostsYet: '还没有人发表内容哦',
  column: '专栏',
  new: '新帖',
  inviteYou2Reply: '等邀请您回答',
  lastReply: '最后回复',
  posts: '帖子',
  followers: '粉丝',
  fields: '领域：',
  setNickname: '设置昵称',
  settingANickname: '设置一个昵称，可以更容易被其他人记住哦！',
  ifDoNotSetting: '如暂不设置，可在设置-个人资料中更改昵称',
  nickname: '昵称',
  ok: '提交',
  cancel: '暂不设置',
  nicknameModified: '昵称修改成功',
  changeNicknameLater: '您稍后可以在个人中心更改昵称哦~',
  completeInformation: '完善信息',
  aquireCompletePhone:
    '应《中华人民共和国网络安全法》要求，使用互联网服务需进行账号实名认证。为了保障社区账号的正常使用，请尽快完成手机号验证，感谢您的理解和支持。',
  name: '姓名',
  enterName: '请输入姓名',
  validateName: '请输入姓名',
  countryOrRegion: '国家/地区',
  phone: '手机',
  enterTel: '请输入手机号',
  verificationCode: '验证码',
  enteringVerificationCode: '请输入6位验证码',
  getCode: '获取验证码',
  resend: '重新发送',
  sendCode: '重新发送',
  email: '邮箱',
  enterEmail: '请输入邮箱',
  industry: '行业',
  selectRegion: '请选择国家/地区',
  company: '公司',
  enterCompany: '请输入并选择公司名称',
  ensureAccurate: '【请填写真实信息，以免影响礼品发放】',
  selectIndustry: '请选择所属行业',
  enterCorrectPhone: '请输入正确的电话号',
  checkMobile: '手机号码不正确，请检查',
  emailFormat: '邮箱格式不正确',
  phoneFormat: '电话格式不正确',
  codeCannotEmpty: '验证码不能为空',
  enterCorrectCode: '请输入正确的验证码',
  companyLong: '公司名称过长({count})',
  enterNickName: '请输入昵称',
  nameLength: '昵称只能包含2 ~ 24个字符',
  nameRulesChange: '昵称中含有特殊字符，再换一个吧',
  nameMaxLength: '姓名过长({count})',
  nameRules: '姓名所含字符不规范，只能包含0-9a-zA-Z,._和中文！',
  profileDetails: '详细资料',
  basicInformation: '基本信息',
  curIndustry: '所在行业',
  curCompany: '公司单位',
  partnership: '伙伴类型',
  consumerInfo: '消费特征',
  activityLevel: '活跃程度',
  professionalism: '专业度',
  interviews: '访谈记录',
  honors: '荣誉',
  awards: '获奖记录',
  userTracking: '用户足迹',
  userSource: '来源',
  userActivities: '活动历程',
  levelUpStature: '积分{num1},距离下一级还需{num2}积分',
  showMore: '展开',
  showLess: '收起',
  huaweiJointDefinition: '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为专家之间交流的互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  shareUrStories: '分享数字化行业应用（底部图标弹窗）',
  checkIn: '签到',
  myProfile: '身份验证',
  myTasks: '我的任务',
  messages: '私信',
  experiencePoints: '智豆',
  weChat: '微信绑定',
  notifications: '提醒',
  inSiteIssue: '站务',
  industryActivities: '行业沙龙',
  more: '更多',
  startTime: '开始时间',
  ongoing: '进行中',
  comingSoon: '即将开始',
  bulletin: '社区公告',
  mostActiveThisMonth: '月度活跃专家',
  postNReplies: '发回帖数',
  daysActive: '活跃天数',
  getStarted: '如何定义',
  forBeginners: '新手FAQ',
  joinAGroup: '加入圈子',
  proposeProduct: '提出产品建议和需求',
  discussWithHuawei: '与华为专家深入讨论',
  productTeam: '产品管理团队进行评审',
  proposalAccepted: '建议和需求纳入版本',
  giftRedemption: '礼品兑换动态',
  newGifts: '上架新品',
  onlyYYY: '你与xxx还差yyy智豆',
  providesAPlace: '华为JDC（华为产品定义社区）是外部行业专家与华为专家围绕行业特点和关键技术进行交流、互动的平台，让行业专家可以直接参与华为产品/解决方案的定义中来，从而推动关键行业产品/解决方案规划和行业整体数字化的发展。',
  recommendedUsers: '推荐关注',
  peopleFollowers: '{count}人已关注',
  follow: '关注',
  following: '已关注',
  mutual: '互相关注',
  information: '提示',
  unfollowThisUser: '确定要取消关注吗？',
  yes: '取消关注',
  no: '我再想想',
  topSuggestions: '获奖产品建议',
  createSuggestion: '提建议赢智豆',
  inviteYou2Reply: '等邀请您回答',
  oK: '确定',
  joinGroup: '选择加入圈子',
  groupsThat: '为您推荐了你可能喜欢的圈子，选择加入圈子一探究竟吧!',
  successful: '成功',
  joinLater: '下次再说',
  joinNow: '立即加入',
  inAccordance: '应《中华人民共和国网络安全法》要求，使用互联网服务需进行账号实名认证。为了保障社区账号的正常使用，请尽快完成手机号验证，感谢您的理解和支持。',
  resend: '重新发送',
  enterACompany: '请输入并选择公司名称',
  combineAccounts: '提交',
  thisMobilePhone: '账号绑定',
  combinTheAccounts: '该手机已注册以下账号，xxxx',
  select: '是否绑定？',
  selectOrEdit: '选择',
  pleaseSelect: '请选择或编辑您的个人信息，您也可以稍后在个人信息中修改。绑定后两个账号的发回帖、积分、好友等数据将会被合并。',
  yesIWant: '请选择邮箱',
  noTheyAre: '请选择行业',
  selectTheAccount2Combine: '请选择公司',
  congratulationsYou: '是的，我要绑定所选账号',
  notLogged: '不，上述都不是我的账号',
  unable2Cancel: '请选择所要绑定的账号',
  viewProfile: '请选择所要绑定的账号',
  currentActivation: '恭喜，您已完成账号绑定!',
  myMedal: '我的勋章',
  hisMedal: '他的勋章',
  hasChoosedLabel: '已选标签',
  action: '动作',
  authenticationType: '认证类型',
  authenticationInformation: '认证信息',
};
