import UserAvatar from '@/components/refactorJdc/common/UserAvatar.vue';
import env from '@/config/env';
import { windowOpen } from '@/utils/common/CommonUtils';
import { openLoginDialog } from '@/utils/web/commonUtils';
import { IS_OVERSEAS } from '@/utils/web/constant';
const {
  uniportalLoginURL
} = env;
export default {
  components: {
    UserAvatar
  },
  props: {
    baseJumpUrl: {
      type: String,
      default: '/jdc/refactor'
    },
    baseApiName: {
      type: String,
      default: 'refactorApi'
    }
  },
  data() {
    return {
      IS_OVERSEAS
    };
  },
  computed: {
    userInfoGet() {
      return this.$store.state.user.userInfoGet;
    },
    isLogin() {
      return this.$store.state.user.baseInfo.uid;
    },
    isAdmin() {
      return this.$store.state.user.baseInfo.isAdmin === 1;
    },
    isInner() {
      return window.location.host === 'dggcip08-ap.huawei.com';
    },
    user() {
      return Object.assign(this.$store.state.user.baseInfo);
    }
  },
  methods: {
    /**
     * 增加函数：点击展示下拉框
     */
    dropShow() {
      this.$refs.dropdown.handleOpen();
    },
    login() {
      if (!this.isLogin) {
        openLoginDialog();
      }
    },
    getUserSpace() {
      window.open(`${this.baseJumpUrl}/profile?uid=${this.isLogin}`, this.isOverseas ? '_self' : '_blank', 'noopener noreferrer');
    },
    handleUserCommand(command) {
      if (command === 'logout') {
        const logoutUrl = this.$rm.api[this.baseApiName]?.user?.logout;
        const queryUrl = window.location.href;
        const url = `${logoutUrl}?referer=${queryUrl}`;
        this.$rm.api.GET(url, null, env.newApiBaseURL).then(res => {
          if (res.code === this.$rm.errCode.success) {
            window.open(res.loginUrl, '_self');
          }
        });
      } else if (command === 'userspace') {
        window.open(`${this.baseJumpUrl}/settings`, '_self');
      } else {
        this.getUserSpace();
      }
    }
  }
};