/*
 * @Author:
 * @Date: 2022-09-22 18:29:01
 * @Description: 未登录重新跳转
 */
import env from '@/config/env.js';
import { IS_OVERSEAS } from '@/utils/web/constant';
const url = env.uniportalLoginURL;
export default {
  toUniportal(backUrl, locationUrl) {
    let finalyUrl = '';
    if (!backUrl) {
      finalyUrl = locationUrl;
    } else {
      finalyUrl = backUrl;
    }
    const lang = IS_OVERSEAS ? '&lang=en_US' : '';
    const uniportalUrl = url + encodeURIComponent(finalyUrl) + lang;
    window.open(uniportalUrl, '_self');
  },
};
