const array = {
  name: 'Array',
  /**
   * 移除传入数组中指定的项
   *
   * @param {any} arr
   * @param {any} item
   * @returns
   */
  remove(arr, item) {
    if (arr.length) {
      const index = arr.indexOf(item);
      if (index > -1) {
        return arr.splice(index, 1);
      }
    }
  },
  asc(first, second) {
    if (first.index === second.index) {
      return 0;
    }
    if (first.index < second.index) {
      return -1;
    } else {
      return 1;
    }
  },
  desc(first, second) {
    if (first.index === second.index) {
      return 0;
    }
    if (first.index > second.index) {
      return -1;
    } else {
      return 1;
    }
  },
  removeRepeat(arr) {
    const newArr = [];
    const repeatArr = [];
    for (let i = 0, len = arr.length; i < len; i++) {
      if (arr.indexOf(arr[i]) === i) {
        newArr.push(arr[i]);
      } else {
        repeatArr.push(arr[i]);
      }
    }
    return { newArr, repeatArr };
  },
  // 将Array转成逗号分隔的String
  changeArrToStr(arr) {
    return arr ? arr.join(',') : '';
  },
  // 将一维数组(arr)变为对应维度(num)下的二维数组,如arr=[1,2,3,4],转变为2维数组后，[[1,2],[3,4]]
  getTwoDimensionalArray(arr, num) {
    const list = [];
    for (let j = 0; j < Math.ceil(arr.length / num); ++j) {
      list[j] = [];
    }
    for (let i = 0, k = 0; i < arr.length; ++i) {
      if (i > 0 && i % num === 0) {
        list[++k][i % num] = arr[i];
      } else {
        list[k][i % num] = arr[i];
      }
    }
    return list;
  },
  // 数组去重
  unique(arrayIns) {
    const temp = []; // 一个新的临时数组
    for (let i = 0; i < arrayIns.length; i++) {
      if (temp.indexOf(arrayIns[i]) === -1) {
        temp.push(arrayIns[i]);
      }
    }
    return temp;
  },
};

export default array;
