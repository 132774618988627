const buriedCode = function(router) {
  try {
    let { pageCode, pageCodes } = router.meta
    if (pageCode) {
      window.hwa('trackPageView', pageCode)
    } else if (pageCodes) {
      let query = router.query
      let queryKey = Object.keys(query)
      let keys = Object.keys(pageCodes)
      let useKey = keys[0] || ''
      queryKey.forEach(item => {
        let findKey = keys.find(keyItem => keyItem === item)
        useKey = findKey !== undefined ? findKey : useKey;
      })
      if (useKey) {
        let queryKeyValue = query[useKey] || 'default'
        pageCode = pageCodes[useKey][queryKeyValue]
        pageCode && window.hwa('trackPageView', pageCode)
      }

    }
  } catch (e) {
    return e
  }
}

export { buriedCode }
