export default {
  discussTitle: '',
  discussDescription: '',
  discussKeywords: '',
  suggestTitle: '',
  suggestDescription: '',
  suggestKeywords: '',
  interactionTitle: '',
  interactionDescription: '',
  interactionKeywords: '',
  defaultTitle: '',
  defaultDescription: '',
  defaultKeywords: '',
  askpeersTitle: '',
  askpeersDescription: '',
  askpeersKeywords: '',
  openClassTitle: '',
  openClassDescription: '',
  openClassKeywords: '',
  industryReportsTitle: '',
  industryReportsDescription: '',
  industryReportsKeywords: '',
};
