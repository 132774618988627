let html = {
  // HTML工具类
  name: 'Html',

  /**
   * 处理HTML中的表格
   */
  handleTable(html) {
    return html.replace(/<table[^<]*>/g, '<table cellpadding="0" cellspacing="0">')
  },

  /**
   * 为图片添加链接
   */
  addImageLink(html) {
    const regex = /(<[^a][^<]*>)(<img[^<]*src="([^"]+)"[^<]*>)/g
    return html.replace(regex, '$1<a href="$3" target="_blank">$2</a>')
  },

  /**
   * HTML编码
   */
  htmlEncode(str) {
    if (!str || str.length === 0) return ''
    return str.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/ /g, '&nbsp;')
      .replace(/'/g, '&#39;')
      .replace(/"/g, '&quot;')
      .replace(/\n/g, '<br/>')
  },

  /**
   * HTML解码
   */
  htmlDecode(str) {
    if (!str || str.length === 0) return ''
    return str.replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&nbsp;/g, ' ')
      .replace(/&#39;/g, '\'')
      .replace(/&quot;/g, '"')
      .replace(/&middot;/g, '·')
      .replace(/&lsquo;/g, '‘')
      .replace(/&rsquo;/g, '’')
      .replace(/&ldquo;/g, '“')
      .replace(/&rdquo;/g, '”')
      .replace(/&hellip;/g, '...')
      .replace(/&asymp;/g, '≈')
      .replace(/&#x3d;/g, '=')
      .replace(/&#x2f;/g, '/')
      .replace(/&#x28;/g, '(')
      .replace(/&#x29;/g, ')')
      .replace(/&#xa;/g, '\n')
      .replace(/&amp;/g, '&')
      .replace(/&bull;/g, '•');
  },

  /**
   * HTML纯文本
   */
  htmlPlainText(html) {
    if (!html || html.length === 0) return ''
    return html.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
      .replace(/<[^>]+?>/g, '')
      .replace(/\s+/g, ' ')
      .replace(/ /g, ' ')
      .replace(/>/g, ' ')
  },
  /**
   * base64图片转码
   */
  handleImage(base64URL) {
    if (!base64URL) return ''
    return `data:image/jpeg;base64,${ base64URL }`
  },
  // 将本地图片转化为base64
  imageToBase64(file) {
    return new Promise((resolve) => {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function() {
        resolve(reader.result)
      }
    })
  },
  getRange() {
    const selection = window.getSelection()
    if (selection && selection.rangeCount > 0) {
      return selection.getRangeAt(0)
    }
  },

  getAtAndIndex(text, ats) {
    return ats.map((at) => {
      return { at, index: text.lastIndexOf(at) }
    }).reduce((a, b) => {
      return a.index > b.index ? a : b
    })
  },

  getPrecedingRange() {
    const r = this.getRange()
    if (r) {
      const range = r.cloneRange()
      range.collapse(true)
      range.setStart(range.endContainer, 0)
      return range
    }
  },
  // 去掉富文本的标签
  handleRichText(name) {
    if (name) {
      return name.replace(/\//g, '')
        .replace(/\[url.*?\]/g, '')
        .replace(/\[url.*?/g, '') // 1.全局去除/ 2.去除[url]及里面包含的内容  3.去除[url
    } else {
      return null
    }
  },
  // 格式化富文本
  formateRichText(richText) {
    if (!richText || richText.length === 0) return ''
    return richText.replace(/\r\n/g, '').replace(/<img[^<]*>/g, '[图片]').replace(/<[^<]*>/g, '')
  },
  filterHtml(html) {
    if (!html || html.length === 0) return ''
    return html.replace(/<br\s?\/?>/, ' ').replace(/<\/?[^>]*>/g, '')
  },
  // xss过滤外链
  filterLink(html) {
    if (!html || html.length === 0) return ''
    const regex = '<(?<b>[\\w]+)(?<c>[^>]*)?(src)[\\s]*=' +
      '[\\s]*[\'"]?(http:\\/\\/|https:\\/\\/)((?!(alpha\\.jdc\\.huawei\\.com|alpha\\.en\\.jdc\\.huawei\\.com|' +
      'beta\\.jdc\\.huawei\\.com|forum\\.huawei\\.com|dev\\.jdc\\.huawei\\.com|beta\\.en\\.jdc\\.huawei\\.com|' +
      'uat\\.jdc\\.huawei\\.com|kwesit\\.huawei\\.com|jdc100\\.huawei\\.com|en\\.jdc\\.huawei\\.com|' +
      'sit.jdc.huawei.com))[^\\s\'">])+' +
      '[\'"]?[\\s]*(?<a>[^>]*)?>'
    const regexObj = new RegExp(regex, 'gi')
    return html.replace(regexObj, '<$<b>$<c>$<a>>')
  },
  handleImg(html) {
    if (!html || html.length === 0) return ''
    return html.replace(/<img[^>]*>/g, '[图片]')
  },
  handleLinkImg(html) {
    if (!html || html.length === 0) return ''
    return this.filterLink(this.handleImg(html))
  },
  // 从html中获取img中src的属性值
  getSrcFromHtmlImg(htmlStr) {
    const imgReg = /<img.*?(?:>|\/>)/gi
    const imgList = htmlStr.match(imgReg)
    const srcReg = /\bsrc\b\s*=\s*['"]?([^'"]*)['"]?/i
    const res = []
    if(!imgList) {
      return res
    }
    for(let i = 0; i < imgList.length; i++) {
      const src = imgList[i].match(srcReg)
      // 获取图片地址
      if(src && src[1]) {
        res.push(src[1])
      }
    }
    return res
  },
  // 获取链接中指定参数的值
  getLinkParamValue(link, name) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
    // 需要转义一遍 ?path=202308/01/112839f8108b2a-9017-43a0-8ec2-24c6248b820c.jpg&amp;aid=169335&amp;bussinessType=3&amp;tid=527907
    const search = this.htmlDecode(link.split('?')[1] || '')
    let r = search.substr(1).match(reg) // 获取url中"?"符后的字符串并正则匹配
    let context = ''
    if (r !== null) {
      context = r[2]
    }
    reg = null
    r = null
    return context === null || context === '' || context === 'undefined' ? '' : context
  },
  // 从html中获取aid
  getAttachList(html) {
    const srcList = this.getSrcFromHtmlImg(html)
    let attachList = []
    srcList.forEach(item => {
      const aid = this.getLinkParamValue(item, 'aid')
      if(aid) {
        attachList.push(parseInt(aid))
      }
    })
    return attachList
  },
}

export default html
