export default {
  singlechoiceVote: '单选投票',
  multichoiceVote: '多选投票',
  maxOf2: '（最多可选2项）',
  voteToSeeResults: '投票后结果才可见',
  '如果xxx为1：xxxVoter': '共有xxx人参与投票',
  viewVoters: '查看投票参与人',
  'votingEndsIn:': '距结束还有：',
  votingEnded: '投票已经结束',
  '5Votes': '5票',
  thePollHasClosedOr: '该投票已经关闭或过期，不能投票',
  submit: '提交',
  thisIsAPublicPoll: '(此为公开投票，其他人可看到您的投票项目)',
  youHaveAlreadyVotedThank: '您已经投过票，谢谢您的参与',
  youUserGroupDoesNot: '您所在的用户组没有投票权限',
  noVotingData: '暂无投票数据',
  selectAtLeastOneOption: '请至少选择一个选项。',
  onlyGroupMembersCanSubmit: '成功加圈方可提交问卷',
  question1IsAMultipleanswer: '题目1是多选题（必选2项），请完成后提交！',
  'sorry,ButThisPollHas': '话题已过期，去看看别的吧',
  youHaveAlreadySubmittedYour: '你已参与过此话题，不可重复提交',
  congratulationsYouHaveEarnedA: '哇哦，恭喜获得抽奖机会',
  goDrawNow: '立即抽奖',
  viewOriginalPost: '查看原帖',
  winners: '中奖名单',
  '如果xx为1：xxWinnerInTotal;Xx': '共xx人 已领取xx人',
  grant50Points: '以智豆形式发放50智豆',
  claimed: '已领取',
  prizeInformation: '奖品信息',
  "you'reAWinner": '您已获奖',
  claimYourPrizeWithinXxx: '请在xxx内领取',
  youWonAnAward: '得奖了，说说获奖感言吧',
  "youHaven'tCommentedYet": '你还没有评论！',
  youHavePublishedYourAcceptance: '发布获奖感言成功',
  weHaveFoundTheseGroups: '结果: 找到相关圈子',
  '如果xxx为1：WeHaveFoundXxxRelated': '结果: 找到相关主题xxx个',
  '如果xxx为1：xxxReply': 'xxx个回复',
  '如果xxx为1：view': 'xxx次查看',
  weHaveFoundTheFollowing: '以下是查找到的用户列表(最多显示 100 个)',
  noMatchingResults: '对不起，没有找到匹配结果。',
  searchKeywords: '请输入搜索内容',
  'searchUsername/nickname': '请输入用户昵称/用户名',
  enterTwoOrMoreCharacters: '请输入2个及以上字符!',
  privacyStatement: '隐私声明',
  termsOfUse: '用户协议',
  jDCPrivacyStatement: '《华为产品定义社区隐私声明》',
  jDCTermsOfUse: '《华为产品定义社区用户协议》',
  huaweiJointDefinitionCommunityIs:
    '华为产品定义社区是一个知识社区，是华为产品用户、行业大咖和华为专家之间交流的互动平台，是用户反馈华为产品体验，提出产品改进建议和新需求的便捷渠道。',
  weWelcomeAllSuggestionsFor: '诚邀您对华为产品提出建议，一起定义下一个产品。',
  agree: '同意',
  disagree: '不同意',
  youCanUseThisProduct: '你只有同意用户协议和隐私声明才可以使用本产品及服务',
  congratulationsYouEarnedTheXxx: '恭喜你获得xxx',
  redeemForYourPrize: '去兑奖',
  tag: '标签',
  enterATag: '请输入标签',
  noRelatedPostsWereFound: '没有找到与xxx标签相关的帖子，您可以继续搜索或者返回标签首页',
  tagNotFound: '抱歉，该标签不存在',
  topicName: '主题名称',
  reward: '悬赏',
  rewardInfo: '悬赏{value}',
  price: '价格',
  resolved: '已解决',
  images: '图片附件',
  author: '作者',
  posted: '发帖时间',
  replies: '回复数',
  views: '浏览数',
  productExpertStatistics: '产品线专家统计',
};
