// #判断国内版、海外版、存储环境
import env from '@/config/env.js';
import { cookie } from '@/utils/common/CommonUtils.js';

export const IS_EN_JDC = env.curVersionEnv === 'enJdc';

export const IS_OCEANCLUB = env.curVersionEnv === 'oceanClubJdc';

export const IS_OVERSEAS = IS_EN_JDC || IS_OCEANCLUB;

// 特殊模板
export const PRODUCT_MODEL = IS_EN_JDC ? 'Product Model' : '产品型号';
export const PRODUCT_INDUSTRY = IS_EN_JDC ? 'Product Industry' : '产品行业';

//签署cookie信息 ACCEPT_ALL 需要埋码，ONLY_REQUIRED：不需要埋码
export const ACCEPT_ALL = 'ACCEPT_ALL';
export const ONLY_REQUIRED = 'ONLY_REQUIRED';

/**
 * @description 根据cookie信息判断海外JDC是否埋码,国内版都需要埋码
 * @returns {Boolean} ACCEPT_ALL 需要埋码
 */
export const isBuriedPageCode = () => {
  if (!IS_OVERSEAS) {
    return true;
  }
  if (cookie.getCookie('hw_privacy_cookie_jdc_en') === ACCEPT_ALL && IS_OVERSEAS) {
    return true;
  }
  return false;
};
/**
 * hw_privacy_cookie_jdc_en :存储是否选择FunctionalCookies
 * @returns {boolean}
 */
export const notSigningCookies = () => !cookie.getCookie('hw_privacy_cookie_jdc_en') && IS_OVERSEAS;
/**
 * @description 存储cookie信息，时长30天
 * @param val 存储是否选择FunctionalCookies
 * @params {String}
 */
export const setSelectCookies = val => {
  cookie.setCookie('hw_privacy_cookie_jdc_en', val, 30);
};
