import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d2b4a848"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["xlink:href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _mergeProps({
    class: "iconfont svgIcon",
    "aria-hidden": "true"
  }, _ctx.$attrs), [_createElementVNode("use", {
    "xlink:href": `#icon-${$props.icon}`
  }, null, 8, _hoisted_1)], 16);
}