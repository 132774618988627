export default {
  homeTitle: '华为JDC - 行业数字化从业者交流平台/华为社区',
  homeKeywords:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  homeDescription:
    '华为,JDC,行业数字化,鸿蒙,问界,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,最佳实践,知识共享,解决方案,行业洞察',
  messageTitle: '消息-华为JDC /华为社区',
  messageKeywords:
    '通知,系统消息,私信,点赞,评论,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  messageDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  beanTitle: '智豆兑换-华为JDC /华为社区',
  beanKeywords: '华为JDC智豆兑换，智豆轻松兑换好礼。华为5G旗舰手机,笔记本,平板,智能穿戴等热门数码产品等你带走。',
  beanDescription:
    '华为,JDC,行业数字化,鸿蒙,问界,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,最佳实践,知识共享,解决方案,行业洞察,华为JDC智豆兑换,华为手机,笔记本,平板,穿戴,积分兑换,智豆兑换',
  groupTitle: '圈子-华为JDC 行业数字化从业者交流平台/华为社区',
  groupKeywords:
    '华为JDC圈子聚集了金融、交通、电力、制造、教育、运营商、医疗、石油石化行业等不同行业的用户，以及数字能源,数据中心,智慧矿山,农业,光伏产业等领域的从业者，共同围绕数字化升级需求和痛点在圈子内参与讨论。',
  groupDescription:
    '圈子,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  replyTitle: '回复与评论-华为JDC /华为社区',
  replyKeywords:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  replyDescription:
    '帖子,回帖,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  inviteTitle: '邀请回答-华为JDC /华为社区',
  inviteKeywords:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  inviteDescription:
    '邀请回答,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  likeTitle: '收到的赞-华为JDC /华为社区',
  likeKeywords:
    '收到的赞,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  likeDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  atTitle: "{'@'}我-华为JDC /华为社区",
  atDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  atKeywords:
    "{'@'}我,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验",
  systemTitle: '系统通知-华为JDC /华为社区',
  systemDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  systemKeywords:
    '系统通知,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  chatTitle: '私信-华为JDC /华为社区',
  chatKeywords:
    '私信,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  chatDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  beanrecordTitle: '{nickname}智豆记录-华为JDC /华为社区',
  beanrecordKeywords:
    '智豆,智豆余额,智豆记录,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  beanrecordDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  prizerecordTitle: '{nickname}兑换记录-华为JDC /华为社区',
  prizerecordKeywords:
    '华为,JDC,行业数字化,鸿蒙,问界,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,最佳实践,知识共享,解决方案,行业洞察,华为JDC智豆兑换,华为手机,笔记本,平板,穿戴,积分兑换,智豆兑换',
  prizerecordDescription: '华为JDC智豆兑换，智豆轻松兑换好礼。华为5G旗舰手机,笔记本,平板,智能穿戴等热门数码产品等你带走。',
  groupIntroTitle: '{group}介绍-华为JDC /华为社区',
  groupIntroKeywords:
    '圈子简介,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  groupIntroDescription:
    '{group}：{group}华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  groupDetailTitle: '{groupDetail}-华为JDC /华为社区',
  groupDetailKeywords:
    '圈子,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  groupDetailDescription:
    '{groupDetail}：{groupDetail}华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  completedInfoTitle: '完善用户信息-华为JDC /华为社区',
  completedInfoKeywords:
    '完善信息用户,华为,JDC,行业数字化,鸿蒙,问界,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,最佳实践,知识共享,解决方案,行业洞察',
  completedInfoDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  postDetailTitle: '{topicTitle}-{groupDetail}-华为JDC/华为社区',
  postDetailKeywords:
    '华为,JDC,行业数字化,鸿蒙,问界,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,最佳实践,知识共享,解决方案,行业洞察',
  postDetailDescription: '',
  safetyTipsTitle: '安全提示-华为JDC /华为社区',
  safetyTipsKeywords:
    '安全提示,华为,JDC,行业数字化,鸿蒙,问界,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,最佳实践,知识共享,解决方案,行业洞察',
  safetyTipsDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  searchTitle: '搜索-华为JDC/华为社区',
  searchKeywords:
    '搜索,华为,JDC,行业数字化,鸿蒙,问界,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,最佳实践,知识共享,解决方案,行业洞察',
  searchDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  relatedUserTitle: '相关用户-华为JDC /华为社区',
  relatedUserKeywords:
    '相关用户,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  relatedUserDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  relatedCircleTitle: '相关圈子-华为JDC /华为社区',
  relatedCircleKeywords:
    '相关圈子,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  relatedCircleDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  reportTitle: '举报-华为JDC /华为社区',
  reportKeywords:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  reportDescription:
    '举报,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  devicesTitle: '登录设备管理-华为JDC /华为社区',
  devicesKeywords:
    '登录设备管理,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  devicesDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  newThreadTitle: '发布帖子-华为JDC /华为社区',
  newThreadKeywords:
    '发布帖子,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  newThreadDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  tradeRecordTitle: '礼品兑换记录-华为JDC /华为社区',
  tradeRecordKeywords:
    '华为,JDC,行业数字化,鸿蒙,问界,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,最佳实践,知识共享,解决方案,行业洞察,华为JDC智豆兑换,华为手机,笔记本,平板,穿戴,积分兑换,智豆兑换',
  tradeRecordDescription: '华为JDC智豆兑换礼品，赚取智豆，轻松在华为JDC兑换华为5G旗舰手机,笔记本,平板,智能穿戴等热门数码产品',
  qrCodeTitle: '参会二维码列表-华为JDC /华为社区',
  qrCodeKeywords:
    '参会二维码列表,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  qrCodeDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  qrDetailTitle: '参会二维码-华为JDC /华为社区',
  qrDetailKeywords:
    '参会二维码,隐私声明,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  qrDetailDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  feedbackTitle: '意见反馈-华为JDC /华为社区',
  feedbackKeywords:
    '意见反馈,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  feedbackDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  pushSettingTitle: '推送通知设置-华为JDC /华为社区',
  pushSettingKeywords:
    '推送通知设置,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  pushSettingDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  privacySecurityTitle: '隐私设置-华为JDC /华为社区',
  privacySecurityKeywords:
    '隐私设置,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  privacySecurityDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  signInTitle: '签到-华为JDC /华为社区',
  signInKeywords:
    '签到,补签到,排行榜,动态,今日推荐,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  signInDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  myCollectionTitle: '{nickname}收藏列表-华为JDC /华为社区',
  myCollectionKeywords:
    '收藏,{nickname}个人主页,个人中心,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  myCollectionDescription: '华为JDC{nickname}的收藏列表',
  personalSignTitle: '签到-华为JDC /华为社区',
  personalSignKeywords:
    '签到,补签到,排行榜,动态,今日推荐,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  personalSignDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  lotteryTitle: '抽奖-华为JDC /华为社区',
  lotteryKeywords:
    '抽奖,奖品,华为,JDC,行业数字化,有奖活动,技术研讨,线上讨论,官方活动,活动颁奖,线下沙龙,技术分享,视频,直播,数字化,行业交流,知识共享,技术创新,知识社区',
  lotteryDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  myPostTitle: '{nickname}帖子列表-华为JDC /华为社区',
  myPostKeywords:
    '帖子,回帖,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  myPostDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  myFocusTitle: '我的关注-华为JDC{nickname}个人主页/华为社区',
  myFocusKeywords:
    '关注,粉丝,{nickname}个人主页,个人中心,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  myFocusDescription: '华为JDC{nickname}的粉丝列表',
  myFansTitle: '我的粉丝-华为JDC {nickname}个人主页/华为社区',
  myFansKeywords:
    '关注,粉丝,{nickname}个人主页,个人中心,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  myFansDescription: '华为JDC{nickname}的粉丝列表',
  dailyTasksTitle: '{nickname}每周任务-华为JDC /华为社区',
  dailyTasksKeywords:
    '签到,任务,活跃值,活跃值记录,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  dailyTasksDescription: '完成华为JDC每周任务，获取丰富智豆奖励，轻松兑换华为5G旗舰手机,笔记本,平板,智能穿戴等热门数码产品',
  hisHomePageTitle: '{nickname}-华为JDC 个人主页/华为社区',
  hisHomePageKeywords:
    '{nickname}，{nickname}个人主页,个人中心,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  hisHomePageDescription: '华为JDC{nickname}的个人中心，提供{nickname}发布的帖子、主题、文章、动态、回复。关注{nickname}账号，第一时间了解动态。',
  selectUserTitle: '选择用户-华为JDC /华为社区',
  selectUserKeywords:
    '华为,JDC,行业数字化,鸿蒙,问界,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,最佳实践,知识共享,解决方案,行业洞察',
  selectUserDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  exclusiveTitle: '新手专属-华为JDC /华为社区',
  exclusiveKeywords:
    '华为,JDC,行业数字化,鸿蒙,问界,金融,交通,电力,制造,教育,运营商,医疗,石油石化,数字能源,数据中心,智慧矿山,农业,光伏,存储,大数据,AI大模型,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,最佳实践,知识共享,解决方案,行业洞察',
  exclusiveDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
};
