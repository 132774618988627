const TYPES = {
  1: {
    img: require('@/assets/images/v1_knowledge.png'),
    bigImg: require('@/assets/images/v1_knowledge_big.png'),
    title: '知识达人'
  },
  2: {
    img: require('@/assets/images/v2_technical.png'),
    bigImg: require('@/assets/images/v2_knowledge_big.png'),
    title: '技术大牛'
  },
  3: {
    img: require('@/assets/images/v3_industry.png'),
    bigImg: require('@/assets/images/v3_knowledge_big.png'),
    title: '行业领袖'
  }
};
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    type: {
      type: [Number, String],
      default: ''
    },
    defaultError: {
      type: String,
      default: require('@/assets/images/noavatar_small.gif')
    },
    useBigIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSmall: false
    };
  },
  computed: {
    getTypeItem() {
      return TYPES[this.type];
    }
  },
  methods: {
    onerrorHandler(e) {
      e.target.src = this.defaultError;
    },
    imgOnload() {
      if (this.$refs.img.offsetWidth < 48) {
        this.isSmall = true;
      }
    }
  }
};