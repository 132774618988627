/*
 * @Author:
 * @Date: 2022-05-06 16:42:32
 * @Description:
 */
/**
 * 本地缓存设置、读取、移除、清理方法
 */
export const LOCALSTORY = {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get(key) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  clear() {
    localStorage.clear();
  },
};

// 获取小程序传来的值
export const getMsgFromMiniOriginal = (val = 'msgFromMiniOriginal') => {
  const searchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of searchParams.entries()) {
    if (key === val) {
      return value;
    }
  }
  return '';
};

export default {
  // 回到页面底部
  toBottom(i) {
    let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
    let scrollHeight = document.documentElement.scrollHeight
    let rollheight = scrollHeight - clientHeight
    document.documentElement.scrollTop += 200
    let c = null
    if (document.documentElement.scrollTop + 1 <= rollheight) {
      c = setTimeout(() => this.toBottom(i), 10)
    } else {
      clearTimeout(c)
    }
  },
  copyUrl(text) {
    let textArea = document.createElement('input')
    document.body.appendChild(textArea)
    textArea.value = text
    textArea.select()
    let msg = false
    try {
      msg = document.execCommand('copy')
    } catch (err) {
      msg = false
    }
    document.body.removeChild(textArea)
    return msg
  },
  // 防抖
  stopClick(name, time) {
    let that = this
    let timer = null
    return new Promise((resolve) => {
      if (that[name]) {
        clearTimeout(timer)
        return false
      }
      that[name] = true
      timer = setTimeout(() => {
        that[name] = false
      }, time)
      resolve()
    })
  },
  /**
   * @description 根据限制长度控制字符数
   * @param {String} str 输入框输入的文字
   * @param {Number} len 限制的字符长度
   * @param {Number} flag  一个中文等于几个字符
   */
  subStrByLimitLen(str, len, flag) {
    let regexp = /[\u4e00-\u9fa5]/g
    if (str.replace(regexp).length <= len) {
      return str
    }

    let repl = ''
    for (let i = 0; i < flag; i++) {
      repl += 'a'
    }
    let temp = Math.floor(len / flag)
    for (let i = temp, j = str.length; i < j; i++) {
      if (str.substring(0, i).replace(regexp, repl).length >= len) {
        if ((str.substring(0, i).replace(regexp, repl).length - len) % flag) {
          return str.substring(0, i - 1)
        }
        return str.substring(0, i)
      }
    }
    return str
  },
  filterEmoji(text) {
    const ranges = [
      '[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]',
      '[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]',

    ]
    return text.replace(new RegExp(ranges.join('|'), 'g'), '')
  },
  delay: (function() {
    let timer = 0
    return function(callback, ms) {
      clearTimeout(timer)
      timer = setTimeout(callback, ms)
    }
  })(),
  LOCALSTORY,
}
