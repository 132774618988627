import mitt from 'mitt';

import ArrayUtils from '@/utils/common/ArrayUtils';
import errCode from '@/utils/common/errCode';
import HtmlUtils from '@/utils/common/HtmlUtils';
import StringUtils from '@/utils/common/StringUtils';

function install(app) {
  if (app) {
    app.config.globalProperties.$bus = mitt();
    app.config.globalProperties.$rm = Object.create(null);
    const utils = [ArrayUtils, StringUtils, HtmlUtils, errCode];
    for (let i = 0, len = utils.length; i < len; i++) {
      const item = Object.create(null);
      Object.keys(utils[i]).forEach(attr => {
        item[attr] = utils[i][attr];
      });
      app.config.globalProperties.$rm[utils[i].name] = item;
    }
  }
}
export default install;
