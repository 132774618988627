import { createStore } from 'vuex'
import router from './modules/router'
import user from './modules/user'
import common from './modules/common'
import post from './modules/post'

export default createStore({
  modules: {
    common,
    user,
    router,
    post,
  },
})
