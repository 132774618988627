/**
 * @file 添加/编辑备注弹窗 国际化-中文
 */
export default {
  editor: '【添加人】：',
  editRemarkMsg: '编辑/添加备注',
  editRemarkTitle: '添加/编辑备注',
  editorTitle: `（当前操作{nickname}）`,
  tipsTitle: '提示：',
  tips: '备注内容所有管理员可见，仅白名单用户组可编辑',
  remarkPlaceholder: '请输入备注名称（不超过20个汉字）',
  remarkNotice: '备注名称不能超过20个汉字',
  submit: '确 定',
  cancel: '取 消',
};
