export default {
  suggestions: '提产品建议',
  settings: '管理',
  edit: '编辑简介',
  descriptionCannotBeLeftBlank: '版块简介不能为空！',
  raiseProductSuggestionsToWin: '提产品建议,赢智豆好礼。请注意社区产品建议请提在站务区。',
  postsInThisSection: '板块内的帖子',
  hot: '热门',
  latestReplies: '最新回复',
  latestTopics: '最新主题',
  featuredSuggestions: '问鼎专家',
  "today'sNewPosts:": '今日新帖：',
  "today'sReplies:": '今日回复：',
  eventAnnouncements: '活动公告',
  productSuggestions: '产品建议',
  onlookers: '围观',
  blockSettings: '版块设置',
  administratorSettings: '管理员设置',
};
