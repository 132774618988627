// *******************************************************************************************************************
//         env 代表埋码环境，值只能是: beta 或 pro
//         env = "beta";   // 代表beta环境
//         env = "pro";    // 代表生产环境
// *******************************************************************************************************************
// 埋码AppKey配置
import store from '@/store/index';
const env = process.env.HWA_F_ENV;
const hwaAppKeys = {
  env,
  appKeys: {
    beta: '204393ab14070a42be43264ba017cf19', // 从UEM的beta环境获取的埋码Key
    pro: '42084d09ff95dc65317cdce885c1bda7', // 从UEM的生产环境获取的埋码Key
  },
  channel: '', // 埋码的频道标识符(可选),比如天幕平台埋码:DMAX
  ABVersion: 'A', // AB版本标识(可选),取值 A或者B;A代表A版本,B代表B版本
  platform: 'web', // 埋码应用的运行平台(可选,默认是web), PC页面:web;we码程序:welink
  enable: true, // 关闭UEM采集开关(可选,默认是开启的)
  storageType: 'sessionStorage', // [sessionStorage | localstorage, 默认localstorage] 采用storage策略
  isStorage: false, //是否使用indexDB，默认true，false不使用
};

const ZERO = 0;
const HWA = 'hwa';
const DEFER = 'defer';

// 采集器配置
(function (h, w, f, t) {
  try {
    if (!h[t]) {
      h.GlobalHwaNamespace = h.GlobalHwaNamespace || [];
      h.GlobalHwaNamespace.push(t);
      h[t] = function (...args) {
        (h[t].q = h[t].q || []).push(args);
      };
      h.trackerload = function (...args) {
        (h[t].q = h[t]?.q.length ? h[t].q : []).unshift(args);
      };
      h[t].q = h[t].q || [];
    }
    let host = '';
    if (f.env === 'pro') {
      host = 'hwa.his.huawei.com';
    } else {
      host = f.env === 'beta' ? 'hwa-beta.his.huawei.com' : '';
    }
    if ('welink' === f.platform) {
      if (f.env === 'pro') {
        host = 'w3m.huawei.com/mcloud/mag';
      } else {
        host = f.env === 'beta' ? 'mcloud-uat.huawei.com/mcloud/mag' : '';
      }
      h.hwahost = `${host}/ProxyForText/hwa_trackload`;
      host = `${host}/fg/ProxyForDownLoad/hwa_f`;
    }
    h.aids = f;
    h.space = t;
    setTimeout(() => {
      const uemMarkuv = window.localStorage.getItem('_uem_markuv_');
      if (!store.state.user.baseInfo.uid && uemMarkuv && uemMarkuv.includes('-')) {
        window.localStorage.removeItem('_uem_markuv_');
      }
      const hwafScript = w.createElement('script');
      const src = `${('https:' === location.protocol ? 'https://' : 'http://') + host}/dist/hwa_f.js?v=${new Date().getTime()}`;
      hwafScript.id = 'hwa_f';
      hwafScript.defer = DEFER;
      hwafScript.src = src;
      hwafScript.async = '1';
      const where = w.getElementsByTagName('head')[0].firstChild || w.getElementsByTagName('head')[0].lastChild || w.getElementsByTagName('script')[0];
      if (where) {
        where.parentNode.insertBefore(hwafScript, where);
      }
    }, ZERO);
  } catch (e) {
    if (!h[t]) {
      h[t] = function () {};
    }
  }
})(window, document, hwaAppKeys, HWA);
