const COM = '@huawei.com';

export default {
  ignoreFriend: 'ignore friend',
  confirmRemoveFriend: 'are you sure to ignore the friend relationship?',
  noticeRemoveFriend: 'notice',
  removeFriendSuccess: 'Operation succeeded!',
  removeFriendOk: 'ok',
  removeFriendConfirm: 'confirm',
  View: 'view',
  Comment: 'comment',
  Favorite: 'favorite',
  forumsProblem: 'I have this problem too',
  Helpful: 'helpful',
  forumsPosts: 'Posts',
  favoriteForum: 'my favorite forums',
  NoData: 'No data',
  catalog: 'CATALOG',
  totalPosts: 'Total Posts : ',
  totalReplies: 'Total Replies : ',
  postsToday: 'Posts For Today : ',
  repliesToday: 'Replies For Today : ',
  Posts: 'posts: ',
  Replies: 'replies: ',
  HuaweiEnterprise: ' - Huawei Enterprise Support Community',
  favorite: 'Add to favorites',
  unfavorite: 'Unfavorite',
  favoriteTip: 'Added to favorites successfully.',
  unfavoriteTip: 'Removed from favorites successfully.',
  post: 'post',
  sharing: 'sharing',
  poll: 'poll',
  creditExchange: 'Credit exchange',
  askHelp: 'Ask for help',
  activity: 'activity',
  debate: 'debate',
  management: 'management',
  viewModerator: 'View moderator',
  popularCollections: 'Popular Collections',
  viewAll: 'View all',
  all: 'all',
  popularWeek: 'POPULAR POSTS OF THE WEEK',
  search: 'search',
  posted: 'posted',
  sortBy: 'sort by',
  showOnly: 'Show only',
  solved: 'Solved',
  help: 'Help',
  digest: 'Highlighted',
  created: 'created ',
  lastReply: 'Last reply ',
  community: 'community',
  forums: 'forums',
  aDayAgo: 'A day ago !',
  aWeekAgo: 'A week ago !',
  aMonthAgo: 'A month ago !',
  aYearAgo: 'A year ago !',
  timePosted: 'time posted',
  numReplies: 'number of replies',
  timeReplied: 'time replied',
  highlightedPosts: 'Highlighted Posts',
  myPosts: 'My posts',
  myReplies: 'My replies',
  posts: {
    postedBy: 'Posted By ',
    at: ' at ',
    reply: 'reply',
    editReply: 'Edit reply',
    editComment: 'Edit comment',
    askHelp: 'Ask for help',
    sharing: 'sharing',
    community: 'community',
    forums: 'forums',
    post: 'post',
    groups: 'groups',
    textEditor: 'Advanced text editor',
    postTo: 'Post to: ',
    activityCategory: 'Activity category: ',
    subcategory: 'subcategory: ',
    title: 'title: ',
    body: 'body: ',
    reward: 'reward: ',
    time: 'time: ',
    options: 'options: ',
    debateTopic: 'Debate topic: ',
    Attachments: 'attachments: ',
    Tags: 'tags: ',
    AdditionalOptions: 'Additional options: ',
    Viewable: 'Viewable To: ',
    RewardReply: 'Reward for Reply: ',
    select: '-select-',
    startDate: 'Start date',
    endDate: 'End date',
    maximumVoting: 'The Maximum Voting Option is Limited to 10.',
    votingOptions: 'Voting Options cannot be lower than 3.',
    fillOutContent: 'Please fill out the complete content.',
    saveFunction: 'The browser does not support save function.',
    SaveSuccess: 'Save success.',
    add: '+Add',
    max: 'max.',
    item: ' item',
    poll: 'poll.',
    days: ' days',
    afterVoting: 'Result visible after Voting',
    participants: 'Show voting participants',
    pros: 'pros',
    cons: 'cons',
    endTime: 'End time: ',
    judge: 'judge: ',
    selectDate: 'Select date',
    inputJudge: 'Input judge',
    keyword: 'please enter the tag keyword.',
    usedTags: 'recently used tags:',
    tips: 'The listed roles are in ascending order in terms of permission. Rols with equal or more permissions than the selected role can view the post.',
    rewardTimes: 'for each reply, total reward times ',
    maxRewardTimes: ', Max reward times per user ',
    rewardingRate: ', Rewarding rate ',
    notice: 'Notice：To ensure the legitimate rights and interests of you, the community, and third parties, do not release content that may bring legal risks to all parties, including but not limited to content concerning politics, pornography, gambling, drug abuse and trafficking, commercial secrets, and personal privacy! For details, see ',
    privacyStatement: ' Privacy statement.',
    save: 'save',
    cancel: 'cancel',
    activity: 'activity',
    Poll: 'poll',
    debate: 'debate',
    dynamicStatus: 'sending dynamic status',
    replyNotifications: 'reply notifications',
    appendSignature: 'append signature',
    forumRequired: 'forum is required.',
    titleRequired: 'title is required.',
    bodyRequired: 'body is required.',
    themeRequired: 'theme type is required.',
    categoryRequired: 'activity category is required.',
    dateRequired: 'activity date is required.',
    pollRequired: 'poll data is required.',
    debateRequired: 'debate data is required.',
    secretTitle: 'Including Third Party’s Trade Secret or No:',
    secretRequired: 'Including Third Party’s Trade Secret or No is Required.',
    secretNotSubmitTips: 'Sorry, your file can not be published for the sake of Third Party’s Trade Secret!',
    secretTips:
      'Third Party’s Trade Secret refers to Third Party’s (other than Huawei’s) technical or commercial information which is unknown to the public,' +
      ' with commercial value, and kept confidential by Third Party. It may include without limitation Price Information, Roadmap, Commercial Authorization, ' +
      `Core Algorithm and Source Code. Should you have any questions, please contact e.support${COM}.`,
    preview: 'preview',
    Add: 'add',
    selectionMsgTips: 'You have Selected the feature of',
    canceledMsgTips: 'You have Cancelled the feature of',
    postPlaceholder: 'Enter clear and accurate title, such as "An interesting case of VXLAN function on CE6800"',
    have: 'You Have',
    coinTips: 'The best answer should be selected within 7 days. Otherwise, the moderator will select one!',
    inputPlaceholder: 'Provide detailed information ( for example, configuration, fault symptoms, and scenario ) to get quick response.',
    uploadTips: 'Each file cannot exceed 50 MB! Supported formats: .gif, .png, .jpg, .jpeg, .pdf, .rar, .zip, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .mp4, .flv',
    umeditorPlaceholder: 'Only videos in .mp4, .avi, or .wmv format not exceeding 100MB are supported. It takes about 10 minutes to transcode the video.',
    changeEditor: 'The current modification will not be retained after switching editor, will continue?',
    editorConfirm: 'confirm'
  },
  agree: 'Agree',
  cancel: 'Cancel',
  favoriteSuccess: 'Favorited. Do you want to receive promotional emails?',
  unfavoriteSuccess: 'Unfavorited. You will no longer receive promotional emails.'
}
