import { createI18n } from 'vue-i18n';

import messages from './langs';

import { IS_OVERSEAS } from '@/utils/web/constant';

const i18n = createI18n({
  legacy: false,
  locale: IS_OVERSEAS ? 'en' : 'zh',
  messages,
});

export default i18n;
