export default {
  myAccount: '个人中心',
  following: 'Ta的关注',
  follow: '关注',
  message: '发私信',
  followers: 'Ta的粉丝',
  follower: '粉丝',
  administrator: '管理员',
  huaweiExpert: '华为专家',
  mVP: '大V认证',
  huaweiEmployee: '华为员工',
  profile: '个人信息',
  dailyTasks: '每日任务',
  myMilestones: '社区历程',
  timeOnline: '在线时间',
  registrationTime: '注册时间',
  lastVisited: '最后访问',
  expertise: '擅长领域',
  addYourExpertiseToTell: '还有擅长领域未设置，完善后让更多人了解你吧！',
  edit: '去完善',
  noExpertiseHasBeenAdded: 'Ta还未设置擅长领域哦',
  hasAchieved: '已点亮{count}枚',
  report: '举报',
  editedSuccessfully: '编辑成功！',
  expertiseCanBeEditedOnce: '擅长领域90天才可以编辑一次，距下次编辑还有1小时',
  expertiseCanBeEditedHours: '擅长领域90天才可以编辑一次，距下次编辑还有{count}小时',
  expertiseCanBeEditedDays: '擅长领域90天才可以编辑一次，距下次编辑还有{count}天',
  editExpertise: '编辑擅长领域',
  selectUpTo3Fields: '选择您所擅长的领域，为您匹配更精确的内容 ，最多可选择3个标签。90 天才可编辑一次，请认真选择',
  save: '保存',
  cancel: '取消',
  youCanSelectUpTo: '最多可选择{count}个标签',
  peopleAchieved: '已有{count}人点亮',
  achievedAt: '点亮时间',
  achieved: '已点亮',
  unlock: '去点亮',
  unachieved: '未获得',
  postsAndReplies: '发回帖列表',
  topic: '帖子',
  reply: '回复',
  all: '全部',
  lastMonth: '最近一个月记录',
  lastMonthNew: '最近一月',
  last3Months: '最近三月',
  lastYear: '最近一年记录',
  lastYearNew: '最近一年',
  replyOrView: '回复/查看',
  topicPosted: '主题发布时间:',
  myExperiencePoints: '我的智豆',
  action: '行为',
  pointChange: '积分变更',
  details: '详情',
  modified: '变更时间',
  myTasks: '我的任务',
  myTaskProgress: '我的任务进度',
  myActivityScore: '我的当前活跃值',
  insufficientActivityScore: '活跃值不足',
  claimGifts: '可领取礼包',
  giftsAlreadyClaimed: '礼包已领取',
  tips: '提示',
  congratulationsGiftsUnlocked: '恭喜您，成功开启礼包',
  experiencePointAwarded: '礼包奖励{giftRerward}智豆',
  oK: '确定',
  extraPoints: '额外奖励',
  postProductSuggestions: '1、发布产品建议贴',
  youCanLeave5Posts: '每日最多奖励5篇（188智豆/篇，除水贴和终端产品建议外）',
  post: '去发帖',
  productSuggestionsEndorsedBy: '2、产品建议帖一旦被华为专家认可，即可获得2500+智豆。（可选任务奖励由管理员手动发放）',
  weeklyTasks: '每周任务',
  activityScoreTally: '活跃值记录',
  alreadySubmitted: '请不要重复提交！',
  change: '变化',
  date: '变化日期',
  activityScore: '活跃值',
  startNow: '去完成',
  completed: '已完成',
  thisTaskIsOverNo: '该任务已经完成，不能重复领取活跃值！',
  followingAndFollowers: '关注与粉丝',
  'expertise:': '领域：',
  recommendedUsers: '推荐关注',
  '{xxx}Follower': 'xxx人已关注',
  myLevel: '我的等级',
  currentLevel: '当前等级',
  youAreAheadOf: '已超过{count}的人',
  myPrivileges: '我的特权',
  earnStature: '经验获取方法',
  period: '周期',
  quantity: '数量',
  stature: '经验',
  statureScoreTally: '经验获取记录',
  selectTime: '选择时间',
  more: '查看更多',
  lastThreeMonths: '最近三个月记录',
  lastHalfYear: '最近半年记录',
  youAreAtLevel: '当前处于{count1}，还差{count2}经验即可升级',
  infinite: '无限',
  pointBalance: '智豆余额',
  obtainUse: '获取/使用',
  pointsToExpire: '将要过期的智豆',
  expireDate: '(过期时间 {expireDate})',
  postText: '帖子',
  noReplies: '暂无回复',
  noPosts: '暂无帖子',
  smartBeanKeywords:
    '智豆,智豆余额,智豆记录,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
  smartBeanDescription:
    '华为JDC（jdc.huawei.com）是一个知识型社区，是华为产品用户、行业大咖与华为技术团队的交流互动平台，是用户反馈华为产品体验，提出产品改进建议与新需求的便捷渠道。',
  followingKeywords:
    '个人主页,个人中心,华为,JDC,数字化,华为社区,知识社区,互动社区,问答社区,产品创新,技术问题,行业趋势,实践案例,知识共享,解决方案,行业洞察,华为产品建议,无线业务,数字能源,数据存储,云核心网,家庭网络WIFI,光产品,数据通信,智慧园区,用户反馈,产品改进,产品创新,用户体验',
};
